@import '../../../../../../styles/resources/vars';
@import '../../../../../../styles/resources/mixins';

.container {
	align-items: center;
	display: flex;
	gap: 10px;
	margin-bottom: 15px;

	.button {
		--app-custom-button-width: 120px;
	}

	.icon {
		--app-custom-button-icon-width: 12px;
		--app-custom-button-icon-height: 12px;
	}

	.collapsed-icon {
		transform: rotate(270deg);

		> svg {
			margin: 4px 0 0;
		}
	}

	.expanded-icon {
		transform: rotate(0deg);
	}
}
