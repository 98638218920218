@import '../../../../styles/resources/vars';
@import '../../../../styles/resources/mixins';

.container {
	color: var(--app-custom-typo-value-color, var(--app-color-mono-5));
	font-style: normal;
	font-weight: 400;
	text-align: var(--app-custom-typo-value-text-align, left);

	&:hover {
		color: var(--app-color-mono-3);
	}

	&:focus-within {
		color: var(--app-color-mono-1);
	}

	::selection {
		background-color: var(--app-color-text-highlight);
	}
}

.size {
	font-size: 13px;
	letter-spacing: 0;
	line-height: 1.2;
}
