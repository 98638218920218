@import '../../../styles/resources/vars';
@import '../../../styles/resources/mixins';

.container {
	--app-textarea-height: var(--app-custom-textarea-height, auto);
	--app-textarea-width: var(--app-custom-textarea-width, 100%);
	--app-textarea-padding: var(--app-custom-textarea-padding, 0 6px);
	--app-textarea-background: var(--app-custom-textarea-background, var(--app-background-component-input));
	--app-textarea-hover-background: var(--app-custom-textarea-hover-background,
	var(--app-background-component-input-hover));
	--app-textarea-focus-background: var(--app-custom-textarea-focus-background,
	var(--app-background-component-input-hover));
	--app-textarea-border: var(--app-custom-textarea-border, 1px solid transparent);
	--app-textarea-hover-border: var(--app-custom-textarea-hover-border, 1px solid transparent);
	--app-textarea-focus-border: var(--app-custom-textarea-focus-border, 1px solid var(--app-color-env));
	--app-textarea-border-radius: var(--app-custom-textarea-border-radius, 6px);
	--app-textarea-color: var(--app-custom-textarea-color, var(--app-color-mono-5));
	--app-textarea-hover-color: var(--app-custom-textarea-hover-color, var(--app-color-mono-3));
	--app-textarea-focus-color: var(--app-custom-textarea-focus-color, var(--app-color-mono-1));
	--app-textarea-font-size: var(--app-custom-textarea-font-size, 13px);
	--app-textarea-font-style: var(--app-custom-textarea-font-style, normal);
	--app-textarea-font-weight: var(--app-custom-textarea-font-weight, normal);
	--app-textarea-line-height: var(--app-custom-textarea-line-height, 1.2);
	--app-textarea-letter-spacing: var(--app-custom-textarea-letter-spacing, normal);
	--app-textarea-selection-background: var(--app-custom-textarea-selection-background, var(--app-color-text-highlight));
	--app-textarea-placeholder-color: var(--app-custom-textarea-placeholder-color, var(--app-color-mono-5));
}

.container {
	@extend %input-reset;
}

.container {
	background: var(--app-textarea-background);
	border: var(--app-textarea-border);
	border-radius: var(--app-textarea-border-radius);
	color: var(--app-textarea-color);
	font-size: var(--app-textarea-font-size);
	font-style: var(--app-textarea-font-style);
	font-weight: var(--app-textarea-font-weight);
	height: var(--app-textarea-height);
	letter-spacing: var(--app-textarea-letter-spacing);
	line-height: var(--app-textarea-font-height);
	min-height: var(--app-textarea-min-height);
	padding: var(--app-textarea-padding);
	width: var(--app-textarea-width);

	&::placeholder {
		color: var(--app-textarea-placeholder-color);
		font: inherit;
	}

	&::selection {
		background-color: var(--app-textarea-selection-background);
	}

	&:hover {
		background: var(--app-textarea-hover-background);
		border: var(--app-textarea-hover-border);
		color: var(--app-textarea-hover-color);
	}

	&:focus {
		background: var(--app-textarea-focus-background);
		border: var(--app-textarea-focus-border);
		color: var(--app-textarea-focus-color);
	}
}
