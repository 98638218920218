@import '../../../../../../styles/resources/vars';
@import '../../../../../../styles/resources/mixins';

.container {
	--app-custom-contenteditable-padding: 8px 8px 8px 0;
	display: flex;
	flex: 0 0 480px;
	flex-direction: column;

	.inputs-container {
		margin-top: 24px;

		> :not(:first-child) {
			margin-top: 24px;
		}
	}
}
