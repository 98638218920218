@import '../../../../styles/resources/vars';
@import '../../../../styles/resources/mixins';

.container {
	position: relative;
}

.popover {
	--app-custom-popover-width: 320px;
}
