@import '../../../styles/resources/vars';
@import '../../../styles/resources/mixins';

.container {
  display: inline-block;
  font-size: 0;
  position: relative;
}

.dark {
  --app-switch-background: var(--app-custom-switch-unchecked-background, var(--app-background-component-input));
  --app-switch-toggle-border: 1px solid rgba(var(--app-color-mono-1-decimal), 0.05);
  --app-switch-toggle-box-shadow: 0px 6px 4px rgba(var(--app-color-mono-10-decimal), 0.5);
}

.light {
  --app-switch-background: var(--app-custom-switch-checked-background, var(--app-background-env));
  --app-switch-toggle-border: 1px solid var(--app-color-mono-10);
  --app-switch-toggle-box-shadow: 0px 4px 4px rgba(var(--app-color-mono-1-decimal), 0.16);
  --app-switch-box-shadow: var(--app-custom-switch-box-shadow);


  &:not(.checked) {
    --app-switch-background: var(--app-custom-switch-unchecked-background, var(--app-background-component-input));
    --app-switch-box-shadow: var(--app-custom-switch-box-shadow, inset 0px 2px 2px rgba(var(--app-color-mono-1-decimal), 0.1));
  }
}

.label {
  align-items: center;
  background: var(--app-switch-background);
  border-radius: 24px;
  box-shadow: var(--app-switch-box-shadow);
  cursor: pointer;
  display: flex;
  height: var(--app-custom-switch-height, 26px);
  justify-content: space-between;
  padding: 3px;
  width: var(--app-custom-switch-width, 40px);


  &.indicator:not(.light)::before {
    background: var(--app-color-env);
    border-radius: 50%;
    box-shadow: 0 0 3px var(--app-color-env);
    content: '';
    display: block;
    height: 8px;
    margin-left: 2px;
    width: 8px;
  }

  &::after {
    background: var(--app-background-component-button);
    background-origin: border-box;
    border: var(--app-switch-toggle-border);
    border-radius: var(--app-custom-switch-control-border-radius, 50%);
    box-shadow: var(--app-switch-toggle-box-shadow);
    content: '';
    display: block;
    height: var(--app-custom-switch-control-height, 20px);
    transform: translateX(var(--app-custom-switch-control-off-state-transform, -14px));
    width: var(--app-custom-switch-control-width, 20px);
  }

  &.checked::after {
    transform: translateX(0);
  }

  &.squared {
    border-radius: 6px;
  }
}

.label:hover {
  &.dark {
    --app-background-component-button: linear-gradient(180deg, #4f4f4f 0%, #363636 100%);
    --app-switch-toggle-box-shadow: 0px 6px 4px rgba(var(--app-color-mono-10-decimal), 0.5);
  }

  &.light {
    --app-background-component-button: var(--app-background-component-hover-button);
    --app-switch-toggle-box-shadow: 0px 4px 4px rgba(var(--app-color-mono-1-decimal), 0.16);
  }
}

.knobs {
  &::before {
    color: var(--app-color-mono-3);
    content: attr(data-on);
    font-size: 13px;
    left: 36px;
    position: absolute;
    top: 9px;
  }

  &.knobs-checked::before {
    content: attr(data-off);
    left: 10px;
  }
}
