@import '../../../../../../../../../../../styles/resources/vars';
@import '../../../../../../../../../../../styles/resources/mixins';

.caret-wrapper {
  cursor: pointer;

  &.expanded {
    .caret {
      transform: rotate(90deg);
    }
  }

  .caret {
    padding-right: 4px;
    --app-custom-icon-color: var(--app-color-mono-6);
    --app-custom-icon-width: 10px;
    --app-custom-icon-height: 10px;

    &:hover {
      --app-custom-icon-color: var(--app-color-mono-3);
    }
  }
}