@import '../../../../styles/resources/vars';
@import '../../../../styles/resources/mixins';

.container {
	color: var(--app-custom-typo-heading-color, var(--app-color-mono-2));
	font-style: normal;
	font-weight: var(--app-custom-typo-heading-weight, 700);
	text-align: var(--app-custom-typo-heading-text-align, left);
}

.special {
	font-style: italic;
	font-weight: 300;
}

.size-xl {
	font-size: 48px;
	letter-spacing: -0.03em;
	line-height: 120%;
}

.size-l {
	font-size: 32px;
	letter-spacing: -0.03em;
	line-height: 38px;
}

.size-m {
	font-size: 24px;
	letter-spacing: -0.02em;
	line-height: 28px;
}

.size-s {
	font-size: 18px;
	letter-spacing: -0.01em;
	line-height: 21px;
}

.size-xs {
	font-size: 15px;
	letter-spacing: -0.01em;
	line-height: 18px;
}

.size-2xs {
	font-size: 13px;
	letter-spacing: -0.01em;
	line-height: 15px;
}
