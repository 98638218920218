@import '../../styles/resources/vars';
@import '../../styles/resources/mixins';

.container {
	background: var(--app-background-component-input);
	border-radius: 5px;
	display: flex;
}

.item {
	--app-custom-button-height: 34px;
	--app-custom-button-padding: 4px;
	--app-button-outer-background: transparent;
	--app-custom-button-border-radius: 2px;
	--app-custom-button-inner-border-radius: 2px;
	flex: 1;

	&.first {
		--app-custom-button-border-radius: 5px 2px 2px 5px;
		--app-custom-button-inner-border-radius: 5px 2px 2px 5px;
	}

	&.last {
		--app-custom-button-border-radius: 2px 5px 5px 2px;
		--app-custom-button-inner-border-radius: 2px 5px 5px 2px;
	}

	&.only {
		--app-custom-button-border-radius: 5px;
		--app-custom-button-inner-border-radius: 5px;
	}
}

.active {
	--app-custom-icon-height: 18px;
	--app-custom-icon-width: 18px;

	--app-button-inner-background: transparent;
	--app-background-component-hover-button: transparent;
	--app-button-inner-border: 0;
	--app-button-outer-border: 0;
	--app-button-inner-box-shadow: none;
	--app-button-label-font-weight: 400;

	border-radius: var(--app-custom-button-border-radius);
	height: var(--app-custom-button-height);
	padding: 1px;
	width: var(--app-custom-button-width, var(--app-custom-button-height));

	@at-root .dark & {
		--app-custom-icon-color: var(--app-color-env);
		--app-button-label-color: var(--app-color-env);
	}

	@at-root .light & {
		--app-button-label-color: var(--app-color-mono-10);
		--app-custom-icon-color: var(--app-color-mono-10);
		background: var(--app-background-env);
		background-clip: content-box;
	}
}
