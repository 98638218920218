@import '../../styles/resources/vars';
@import '../../styles/resources/mixins';

.dark {
	--app-button-outer-border: 1px solid transparent;
	--app-button-inner-border: 1px solid rgba(var(--app-color-mono-1-decimal), 0.08);
	--app-button-outer-box-shadow: 0px 4px 4px -1px rgba(0, 0, 0, 0.4), inset 0px 1px 0px rgba(255, 255, 255, 0.08);
	--app-button-inner-box-shadow: none;

	--app-button-label-color: var(--app-color-mono-3);
	--app-button-label-text-shadow: 0px -1px 0px rgba(var(--app-color-mono-10-decimal), 0.5);

	--app-button-icon-color: var(--app-color-mono-4);
}

.light {
	--app-button-outer-border: 1px solid transparent;
	--app-button-inner-border: 1px solid rgba(var(--app-color-mono-10-decimal), 0.7);
	--app-button-outer-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
	--app-button-inner-box-shadow: none;

	--app-button-label-color: var(--app-color-mono-4);
	--app-button-label-text-shadow: 0px 1px 0px rgba(var(--app-color-mono-10-decimal), 0.5);

	--app-button-icon-color: var(--app-color-mono-4);
}

.container {
	--app-button-outer-background: var(--app-custom-button-inner-background, var(--app-background-component-input));
	--app-button-inner-background: var(--app-custom-button-outer-background, var(--app-background-component-button));
	--app-button-align-items: var(--app-custom-button-align-items, center);
	--app-button-justify-content: var(--app-custom-button-justify-content, center);
	--app-button-height: var(--app-custom-button-height, 34px);
	--app-button-width: var(--app-custom-button-width, auto);
	--app-button-padding: var(--app-custom-button-padding, 7px 9px);
	--app-button-margin: var(--app-custom-button-margin, 0);
	--app-button-border-radius: var(--app-custom-button-border-radius, 6px);
	--app-button-inner-border-radius: var(--app-custom-button-inner-border-radius, 5px);
}

// outer-container
.container {
	align-items: stretch;
	appearance: button;
	background: var(--app-button-outer-background);
	border: var(--app-button-outer-border);
	border-radius: var(--app-button-border-radius);
	box-shadow: var(--app-button-outer-box-shadow);
	display: inline-flex;
	margin: var(--app-button-margin, 0);
	min-height: var(--app-button-height);
	min-width: var(--app-button-height);
	width: var(--app-button-width);

	&:enabled,
	&:any-link {
		&.dark {
			&.major {
				--app-button-outer-border: 1px solid var(--app-color-env);
				--app-button-outer-box-shadow: 0 0 3px var(--app-color-env);
			}
		}

		&.light {
			&.major {
				--app-button-outer-background: var(--app-color-env);
			}
		}
	}

	&:not(.loading):disabled {
		opacity: 0.4;
	}

	&.loading {
		box-shadow: none;
	}

	&.fluid {
		--app-button-width: 100%;
	}
}

// inner-container
.body {
	align-items: var(--app-button-align-items);
	background: var(--app-button-inner-background);
	background-origin: border-box;
	border: var(--app-button-inner-border);
	border-radius: var(--app-button-inner-border-radius);
	box-shadow: var(--app-button-inner-box-shadow);
	display: flex;
	flex: 1;
	justify-content: var(--app-button-justify-content);
	padding: var(--app-button-padding);

	@at-root .container:not(.loading):hover & {
		--app-button-inner-background: var(--app-background-component-hover-button);
	}

	@at-root .container:not(.loading):active & {
		transform: translateY(1px);
	}

	@at-root .container.loading.light & {
		background: var(--app-gradient-color-env);
		border: 0;
	}

	@at-root .container.loading.dark & {
		background: var(--app-background-component-input);
		border: 0;
	}

	@at-root .elastic & {
		--app-button-justify-content: space-between;
	}
}

.icon {
	--app-custom-icon-height: var(--app-custom-button-icon-height, 18px);
	--app-custom-icon-width: var(--app-custom-button-icon-width, 18px);
	--app-custom-icon-color: var(--app-button-icon-color);

	@at-root .container.loading.light & {
		--app-custom-icon-color: var(--app-custom-loading-icon-color, var(--app-color-mono-10));
	}

	@at-root .container.loading.dark & {
		--app-custom-icon-color: var(--app-custom-loading-icon-color, var(--app-color-env));
	}
}

.label {
	align-items: center;
	color: var(--app-button-label-color);
	display: flex;
	font-size: 13px;
	font-style: normal;
	font-weight: var(--app-button-label-font-weight, 500);
	line-height: 1.2;
	text-shadow: var(--app-button-label-text-shadow);
}
