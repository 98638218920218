@import '../../styles/resources/vars';
@import '../../styles/resources/mixins';

.background {
	bottom: 0;
	display: flex;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;

	> svg {
		height: 100%;
		width: 100%;
	}
}
