@import '../../../../../styles/resources/vars';
@import '../../../../../styles/resources/mixins';

.container {
	--app-select-input-height: 38px;
}

.fit-content {
	width: fit-content;
}

.thin-date {
	--app-custom-typo-data-color: var(--app-color-mono-3);
	--app-custom-typo-data-font-weight: 400;
}
