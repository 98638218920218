@import '../../../../styles/resources/vars';
@import '../../../../styles/resources/mixins';

.container {
	--app-custom-typo-label-font-weight: 700;
	align-items: center;
	display: flex;
	height: 100%;
	justify-content: space-between;
	position: relative;
}

.button {
	--app-custom-button-height: 24px;
	--app-custom-button-width: 24px;
	--app-custom-button-padding: 0;

	display: none;
	margin: var(--app-table-cell-padding);

	@at-root .container:hover & {
		display: block;
		position: absolute;
		right: 0;
	}
}

.caret {
	cursor: text;
}

.arrow {
	cursor: default;
}
