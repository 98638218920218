@import '../../../../../../styles/resources/vars';
@import '../../../../../../styles/resources/mixins';

.add-ruleset {

	--app-custom-icon-width: 18px;
	--app-custom-icon-height: 18px;
	--app-custom-button-height: 32px;
	--app-custom-button-width: 125px;
	--app-custom-button-padding: 0;

	--app-custom-icon-color: var(--app-color-mono-4);
	margin-left: 16px;
}
