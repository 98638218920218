@import '../../../../styles/resources/vars';
@import '../../../../styles/resources/mixins';

.container {
	--app-custom-popover-width: auto;
	--app-custom-popover-max-width: auto;
	--app-custom-popover-padding: 6px;
	--app-custom-popover-border-radius: 6px;
	--app-custom-popover-filter: none;
	--app-custom-popover-background: var(--app-background-component-sidebar);
}

.dark {
	--app-custom-popover-border: 1px solid var(--app-background-component-input);
}

.light {
	--app-custom-popover-border: 1px solid var(--app-color-mono-8);
}

.container {
	align-items: center;
	display: flex;
	justify-content: center;
}

.button {
	--app-button-margin: 16px 0 0;
}
