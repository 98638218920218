@import '../../styles/resources/vars';
@import '../../styles/resources/mixins';

.dark {
	--app-image-with-overlay-outer-background: var(--app-color-mono-10);
	--app-image-with-overlay-icon-color: var(--app-color-mono-4);
}

.light {
	--app-image-with-overlay-outer-background: var(--app-color-mono-1);
	--app-image-with-overlay-icon-color: var(--app-color-mono-10);
}

.container {
	--app-image-with-overlay-border-radius: var(--app-custom-image-with-overlay-border-radius, 6px);
	--app-image-with-overlay-height: var(--app-custom-image-with-overlay-height, 32px);
	--app-image-with-overlay-width: var(--app-custom-image-with-overlay-width, 32px);
}

.container {
	border-radius: var(--app-image-with-overlay-border-radius);
	display: flex;
	flex: 0 0 var(--app-image-with-overlay-width);
	height: var(--app-image-with-overlay-height);
	width: var(--app-image-with-overlay-width);
	overflow: hidden;
	position: relative;
	max-width: 100%;
	max-height: 100vh;
}

.image {
	height: 100%;
	object-fit: var(--app-image-object-fit, cover);
	object-position: center;
	width: 100%;
}

.overlay {
	align-items: center;
	background-color: var(--app-image-with-overlay-outer-background);
	bottom: 0;
	display: flex;
	height: 100%;
	justify-content: center;
	left: 0;
	opacity: 0;
	position: absolute;
	right: 0;
	top: 0;
	transition: 0.3s ease;
	width: 100%;
}

.container:hover .overlay {
	opacity: 0.7;
}

.icon {
	--app-custom-icon-height: 18px;
	--app-custom-icon-width: 18px;
	--app-custom-icon-color: var(--app-image-with-overlay-icon-color);
}
