@import '../../../styles/resources/vars';
@import '../../../styles/resources/mixins';

.input {
	@extend %input-reset;
	display: block;
	height: 100%;
	width: 100%;
}

.button {
	cursor: inherit;
	font-size: 0;
}
