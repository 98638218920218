@import '../../../../styles/resources/vars';
@import '../../../../styles/resources/mixins';

.container {
	&.inherit {
		@extend %inherit;
	}

	&.truncated {
		@extend %truncated-text;

		//prevent browser native tooltip in Safari because of text-overflow: ellipsis
		&::after {
			content: '';
			display: block;
		}
	}
}
