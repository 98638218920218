@import '../../../../styles/resources/vars';
@import '../../../../styles/resources/mixins';

.hero {
	--app-custom-typo-heading-color: #fff;
	background: url('../../../../assets/images/home-hero.jpeg') center center;
	background-size: cover;

	border-radius: 10px;
	padding: 56px 40px;
}

.subtitle {
	margin-top: 8px;
	text-transform: uppercase;
}
