@import '../../../../styles/resources/vars';
@import '../../../../styles/resources/mixins';

.container {
	align-items: var(--app-table-cell-vertical-align);
	display: flex;
	height: 100%;
	justify-content: var(--app-table-cell-align);
	padding: var(--app-table-cell-padding);
}
