@import '../../styles/resources/vars';
@import '../../styles/resources/mixins';

.container {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

.content {
	display: flex;
	flex: 1;
}

.top {
	height: 48px;
	top: 0;
	z-index: var(--app-z-index-layer-1500);
}

.center {
	flex: 1;
	min-width: 0;
	position: relative;
}

.left,
.right {
	align-self: flex-start;
	max-width: 240px;
	top: 48px;
	z-index: var(--app-z-index-layer-1000);
}

.top,
.left,
.right {
	-webkit-font-smoothing: antialiased;
	position: sticky;
	transform: translateZ(0);
}
