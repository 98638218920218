@import '../../../styles/resources/vars';

/* stylelint-disable */
.react-datepicker-overrides {
  --app-calendar-width: auto;
  --app-calendar-max-width: 320px;
  --app-calendar-padding: 16px;
  --app-calendar-border: 1px solid var(--app-color-mono-9);
  --app-calendar-border-radius: 12px;
  --app-calendar-filter: drop-shadow(0 16px 48px rgba(0, 0, 0, 0.5));
  --app-calendar-background: var(--app-background-component-popover);
}

.react-datepicker-overrides {
  font-family: "Rubik", sans-serif;
  border: 0;

  background: var(--app-calendar-background);
  border: var(--app-calendar-border);
  border-radius: var(--app-calendar-border-radius);
  filter: var(--app-calendar-filter);
  max-width: var(--app-calendar-max-width);
  padding: var(--app-calendar-padding);
  width: var(--app-calendar-width);

  .react-datepicker__header, {
    background: transparent;
    border: 0;
  }

  &.calendar {
    .react-datepicker__current-month {
      color: var(--app-color-mono-2);
      font-style: normal;
      font-weight:  400;
      font-size:  15px;
      margin-bottom: 8px;
    }

    .react-datepicker__day-name {
      color: var(--app-color-mono-5);
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
    }

    .react-datepicker__week > *, .react-datepicker__time-list-item {
      color: var(--app-color-mono-3);
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
    }


    .react-datepicker__day--outside-month {
      color: var(--app-color-mono-6);
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
    }

    .react-datepicker__day, {
      &:hover {
        box-shadow: inset 0px 0px 0px 1px var(--app-color-env);
        background-color: transparent;
      }
    }

    .react-datepicker__day--keyboard-selected {
      box-shadow: inset 0px 0px 0px 1px var(--app-color-env);
      background-color: transparent;
    }

    .react-datepicker__day--selected {
      color: var(--app-color-env);
      background-color: var(--app-background-component-input);
    }
  }

  &.time {
    .react-datepicker-time__header {
      color: var(--app-color-mono-2);
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
    }

    .react-datepicker__time, {
      background: transparent;
      border: 0;
    }

    .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-list {
      @extend %scroll-bar-reset;
    }


    .react-datepicker__time
    .react-datepicker__time-box
    .react-datepicker__time-list
    .react-datepicker__time-list-item {
      color: var(--app-color-mono-3);
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 16px;

      &:hover {
        box-shadow: inset 0px 0px 0px 1px var(--app-color-env);
        border: 0;
        background-color: transparent;
      }

      &.react-datepicker__time-list-item--selected {
        color: var(--app-color-env);
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        background-color: var(--app-background-component-input);
      }
    }
  }
}
/* stylelint-enable */