@import '../../../../styles/resources/vars';
@import '../../../../styles/resources/mixins';

.container {
	padding: var(--app-table-cell-padding);
	position: relative;
	height: 100%;
	border: 1px solid transparent;

	&:hover {
		border-color: var(--app-color-env);
	}
}

.button {
	--app-custom-button-height: 24px;
	--app-custom-button-width: 24px;
	--app-custom-button-padding: 0;

	display: none;
	margin: var(--app-table-cell-padding);

	@at-root .container:hover & {
		display: block;
		position: absolute;
		right: 0;
		top: 0;
	}
}
