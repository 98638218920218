@import '../../../styles/resources/vars';
@import '../../../styles/resources/mixins';

.input {
	@extend %file-reset;
}

.text {
	margin-left: 8px;
}

.label {
	align-items: center;
	cursor: pointer;
	display: flex;
}
