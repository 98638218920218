@import '../../../../../../styles/resources/vars';
@import '../../../../../../styles/resources/mixins';

.container {
	align-items: center;
	background: var(--app-background-component-panel);
	display: flex;
	height: 48px;
	justify-content: right;
	margin-right: 10px;

	.content {
		align-items: center;
		display: flex;
		gap: 10px;
		justify-content: space-between;
		width: 33%;
	}
}
