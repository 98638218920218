@import '../../../../../../styles/resources/vars';
@import '../../../../../../styles/resources/mixins';

.container {
  width: 100%;
  height: 100%;

  &:hover {
    cursor: pointer;
  }

  & > div {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}