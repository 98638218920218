@import '../../styles/resources/vars';
@import '../../styles/resources/mixins';

.container {
	display: flex;
}

.buttons {
	--app-custom-button-width: 138px;
	align-items: center;
	display: flex;
	gap: 7px;
}
