@import '../../../../../../../../../styles/resources/vars';
@import '../../../../../../../../../styles/resources/mixins';

.inputs {
  display: flex;
  flex-wrap: wrap;
  gap: 12px 8px;

  & > div {
    display: flex;
    flex-direction: column;
    gap: 4px;

    &:first-child {
      width: 100%;
    }

    &:not(:first-child) {
      width: calc(50% - 4px);
    }
  }

  --app-select-input-height: 38px;
}

.width-input {
  @extend %input-spin-buttons-reset;
}
