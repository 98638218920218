@import '../../../../../../../../../../../styles/resources/vars';
@import '../../../../../../../../../../../styles/resources/mixins';

.structure-container-item-wrapper {
  padding: 0;

  &:not(:last-child) {
    margin-bottom: 5px;
  }

  .structure-container-item {
    display: flex;
    align-items: center;
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .structure-items {
    padding-left: 20px;
  }
}