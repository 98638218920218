@import '../../../../styles/resources/vars';
@import '../../../../styles/resources/mixins';

.container {
	width: 754px;

	.heading {
		--app-custom-typo-heading-weight: 500;
		display: flex;
		justify-content: center;
		margin-bottom: 24px;
		margin-top: 24px;
	}

	.main {
		margin: auto 30px;
	}
}
