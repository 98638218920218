@import '../../../../styles/resources/vars';
@import '../../../../styles/resources/mixins';

.container {
	cursor: default;
	display: flex;
	height: 100%;
	width: inherit;
}

.wrapper {
	align-items: var(--app-table-heading-cell-align);
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: var(--app-table-heading-cell-vertical-align);
	width: inherit;
}

.row {
	--app-custom-typo-label-color: var(--app-color-mono-5);
	align-items: var(--app-table-cell-vertical-align);
	display: flex;
	height: var(--app-table-row-height);
	justify-content: var(--app-table-cell-align);
	padding: var(--app-table-cell-padding);
	width: 100%;

	&:hover {
		--app-custom-typo-label-color: var(--app-color-mono-1);
		background-color: var(--app-background-component-input);
	}
}

.label {
	--app-custom-typo-label-font-weight: 700;
	--app-custom-typo-label-font-size: 12px;
	--app-custom-typo-label-color: inherit;
	width: inherit;
}

.primary {
	--app-custom-typo-label-color: var(--app-color-mono-1);
}

.sort {
	--app-custom-icon-height: 18px;
	--app-custom-icon-width: 18px;

	--app-custom-icon-color: var(--app-color-mono-5);

	margin-right: 4px;

	&.sorted {
		--app-custom-icon-color: var(--app-color-env);
	}
}

.filter {
	--app-custom-icon-height: 18px;
	--app-custom-icon-width: 18px;

	--app-custom-icon-color: var(--app-color-mono-5);

	margin-right: 4px;

	&.filtered {
		--app-custom-icon-color: var(--app-color-env);
	}
}

.resizer {
	background: transparent;
	display: inline-block;
	height: 50%;
	position: absolute;
	right: -2px;
	top: 0;

	touch-action: none;
	width: 3px;
	z-index: 1;

	&:hover,
	&.resizing {
		background-color: var(--app-color-env);
		box-shadow: var(--app-glow-env);
		transition: background-color 100ms linear;
	}

	&.full-height {
		height: 100%;
	}
}
