@import '../../../../styles/resources/vars';
@import '../../../../styles/resources/mixins';

.container {
	--app-custom-textarea-padding: 0;
	--app-custom-textarea-focus-background: transaprent;
	--app-custom-textarea-hover-background: transaprent;
	--app-custom-textarea-background: transaprent;
	--app-custom-textarea-hover-border: 0;
	--app-custom-textarea-focus-border: 0;
	--app-custom-textarea-border-radius: 0;
	--app-custom-textarea-border: 0;
	--app-custom-textarea-font-size: 15px;
}

.dark {
	--app-custom-textarea-color: var(--app-color-mono-10);
	--app-custom-textarea-focus-color: var(--app-color-mono-10);
	--app-custom-textarea-hover-color: var(--app-color-mono-10);
}

.light {
	--app-custom-textarea-color: var(--app-color-mono-1);
	--app-custom-textarea-focus-color: var(--app-color-mono-1);
	--app-custom-textarea-hover-color: var(--app-color-mono-1);
}

.container {
	padding-right: 24px;
	//align-items: flex-end;
	//display: flex;
	//flex-grow: 1;
	//justify-content: center;
}

.bubble {
	background-color: rgba(255, 255, 255, 0.7);
	border-radius: 12px;
	padding: 12px;
	width: 100%;
}

.header {
	--app-custom-typo-label-color: var(--app-color-mono-5);
	--app-custom-image-with-overlay-height: 20px;
	--app-custom-image-with-overlay-width: 20px;
}

.header {
	align-items: center;
	display: flex;
	justify-content: space-between;
	margin-bottom: 12px;
}

.logo {
	margin-right: 6px;
}

.now {
	margin-left: auto;
	text-align: right;
}

.body {
	display: flex;
}

.title {
	--app-custom-textarea-font-weight: 600;
}

.image {
	align-items: flex-start;
	display: flex;
	margin-left: 12px;
}

.message {
	width: 100%;
}
