@import '../../../../styles/resources/vars';
@import '../../../../styles/resources/mixins';

.container {
  align-items: center;
  display: flex;
  gap: 5px;
  height: 100%;
  width: 100%;

  .tags {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;

    &.truncate {
      flex-wrap: nowrap;
      overflow: hidden;
    }

    .tag {
      --app-custom-icon-height: 14px;
      --app-custom-icon-width: 14px;
      --app-custom-icon-color: var(--app-color-env);
      --app-custom-typo-paragraph-color: var(--app-color-mono-3);
      align-items: center;
      display: flex;
      gap: 5px;
      padding: var(--app-table-cell-padding);
      max-width: 100%;
    }
  }
}
