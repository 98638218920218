@import '../../../../../../../../../../styles/resources/vars';
@import '../../../../../../../../../../styles/resources/mixins';

.container {
  --app-custom-typo-label-color: var(--app-color-mono-5);

  .label {
    display: block;
    margin-bottom: 4px;
  }
}
