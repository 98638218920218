@import '../../../../../../styles/resources/vars';
@import '../../../../../../styles/resources/mixins';

.container {
	--app-custom-icon-color: var(--app-color-env);
	--app-custom-icon-width: 18px;
	--app-custom-icon-height: 18px;
	display: flex;
	flex-direction: column;

	.tag {
		--app-custom-typo-label-color: var(--app-color-mono-3);
		align-items: center;
		border: 1px solid transparent;
		cursor: pointer;
		display: flex;
		gap: 5px;
		padding: 4px;
		transition: transform 0.1s linear;
		max-width: 100%;

		.caret {
			--app-custom-icon-color: var(--app-color-mono-6);
			--app-custom-icon-width: 10px;
			--app-custom-icon-height: 10px;
		}

		&.selected,
		&.is-dragging {
			border-color: var(--app-color-env);
			border-radius: 6px;
		}

		&.expanded {
			.caret {
				transform: rotate(90deg);
			}
		}

		.dnd-icon {
			--app-custom-icon-color: var(--app-color-mono-6);
			--app-custom-icon-width: 10px;
			--app-custom-icon-height: 13px;
			display: none;

		}

		@at-root .dark {
			.is-dragging {
				box-shadow: 0 2px 8px rgba(0, 0, 0, 0.6);
			}
		}

		@at-root .light {
			.is-dragging {
				box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
			}
		}

		&.is-dragging {
			.dnd-icon {
				display: inline-flex;
			}
		}

		&:hover {
			.dnd-icon {
				display: inline-flex;
			}
		}
	}
}
