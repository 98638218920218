@import '../../../styles/resources/vars';
@import '../../../styles/resources/mixins';

.container {
	border: 1px solid var(--app-border-color-env-indicator);
	background: var(--app-background-env);
	box-shadow: inset 0 2px 5px var(--app-color-env);
	border-radius: 6px;
	font-size: 13px;
	font-style: italic;
	line-height: 1.2;
	text-transform: uppercase;
	height: 32px;
	padding: 8px 7px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.40);
	cursor: pointer;

	&.dark {
		color: var(--app-color-mono-1);
		.icon {
			color: var(--app-color-mono-1);
			--app-custom-icon-color: var(--app-color-mono-1);
		}
	}

	&.light {
		color: var(--app-color-mono-10);

		.icon {
			color: var(--app-color-mono-10);
			--app-custom-icon-color: var(--app-color-mono-10);
		}
	}

	.icon {
		--app-custom-icon-height: 18px;
		--app-custom-icon-width: 18px;

		text-shadow: 0 1px 3px rgba(var(--app-color-mono-10-decimal), 0.4);
	}
}
