@import '../../../styles/resources/vars';
@import '../../../styles/resources/mixins';

.container {
	--app-custom-grid-cols: 5;
	--app-custom-grid-rows: 48px;
	--app-custom-grid-gap-column: 8px;

	background: var(--app-background-component-panel);
}

.container:after {
	content: "";
	border-bottom: 1px solid var(--app-background-component-input);
	display: block;
}

.actions {
	display: flex;
	justify-content: flex-end;
}

.product-link {
	cursor: pointer;
}

.logo {
	border: 1px solid var(--app-border-color-env-indicator);
	--app-image-with-overlay-height: 32px;
	--app-image-with-overlay-width: 32px;
}
