@import '../../../../styles/resources/vars';
@import '../../../../styles/resources/mixins';

.container {
	color: var(--app-custom-typo-data-color, var(--app-color-mono-2));
	font-style: normal;
	font-weight: var(--app-custom-typo-data-font-weight, 700);
	text-align: var(--app-custom-typo-data-text-align, left);
}

.size-l {
	font-size: 32px;
	letter-spacing: -0.03em;
	line-height: 1.2;
}

.size-m {
	font-size: 22px;
	letter-spacing: -0.02em;
	line-height: 1.2;
}

.size-s {
	font-size: 18px;
	letter-spacing: -0.01em;
	line-height: 1.2;
}

.size-xs {
	font-size: 15px;
	letter-spacing: -0.01em;
	line-height: 1.2;
}

.size-2xs {
	font-size: 13px;
	letter-spacing: -0.01em;
	line-height: 1.2;
}

.size-3xs {
	font-size: 11px;
	letter-spacing: -0.01em;
	line-height: 1.2;
}
