@import '../../../../../../../../../styles/resources/vars';
@import '../../../../../../../../../styles/resources/mixins';

.container {
  & div {
    margin: 0;

    > ol, > ul {
      > li {
        margin-left: 2em !important;
      }
    }
  }
}

.layer {
  outline-offset: -1px;
  outline: 1px solid var(--app-color-env);

  &:hover {
    outline: 1px dashed var(--app-color-env);
  }

  span::selection, li::selection, figcaption::selection {
    color: white;
    background: var(--app-color-env);
  }
}
