@import '../../../../styles/resources/vars';
@import '../../../../styles/resources/mixins';

.container {
	align-items: center;
	display: flex;
}

.icon {
	--app-icon-color: var(--app-color-env);
}

.icon-sizes {
	&-xs {
		--app-icon-height: 15px;
		--app-icon-width: 15px;
	}
}

.text {
	--app-custom-typo-label-color: var(--app-color-mono-5);

	flex: 1;
	margin-left: 4px;
}

.label {
	--app-custom-typo-label-vertical-align: middle;
}
