@use '../resources' as mixins;

:root {
	/* APP RELATED VARS: DO NOT MODIFY! */
	// SAFE AREA
	--sat: env(safe-area-inset-top, 0);
	--sar: env(safe-area-inset-right, 0);
	--sab: env(safe-area-inset-bottom, 0);
	--sal: env(safe-area-inset-left, 0);

	// Z-INDEX
	--app-z-index-layer-1: 1;
	--app-z-index-layer-10: 10;
	--app-z-index-layer-100: 100;
	--app-z-index-layer-1000: 1000;
	--app-z-index-layer-1500: 1500;
	--app-z-index-layer-9999: 9999;

	// COLORS
	--app-color-red: #f5122d;
	--app-color-purple: #c31cb6;
	--app-color-indigo: #3425fe;
	--app-color-sky-blue: #199af0;
	--app-color-green: #3dcf35;
	--app-color-yellow: #f5d20d;
	--app-color-orange: #f48c1d;

	--app-glow-env: 0px 0px 3px var(--app-color-env);
	--app-background-env: var(--app-gradient-color-env);

	// THEMES
	&[data-theme='dark'] {
		--app-color-mono-1: #fff;
		--app-color-mono-2: #eee;
		--app-color-mono-3: #ddd;
		--app-color-mono-4: #ccc;
		--app-color-mono-5: #999;
		--app-color-mono-6: #666;
		--app-color-mono-7: #333;
		--app-color-mono-8: #222;
		--app-color-mono-9: #111;
		--app-color-mono-10: #000;

		--app-color-mono-1-decimal: 255, 255, 255;
		--app-color-mono-2-decimal: 238, 238, 238;
		--app-color-mono-3-decimal: 221, 221, 221;
		--app-color-mono-4-decimal: 204, 204, 204;
		--app-color-mono-5-decimal: 153, 153, 153;
		--app-color-mono-6-decimal: 102, 102, 102;
		--app-color-mono-7-decimal: 51, 51, 51;
		--app-color-mono-8-decimal: 34, 34, 34;
		--app-color-mono-9-decimal: 17, 17, 17;
		--app-color-mono-10-decimal: 0, 0, 0;

		--app-background-component-page: var(--app-color-mono-9);
		--app-background-component-sidebar: linear-gradient(180deg, #2d2d2d 0%, #222 100%);
		--app-background-component-panel: linear-gradient(180deg, #2d2d2d 0%, #222 100%);
		--app-background-component-popover: linear-gradient(180deg, #444 0%, #333 100%);
		--app-background-component-button: linear-gradient(180deg, #3b3b3b 0%, #262626 100%);
		--app-background-component-hover-button: linear-gradient(180deg, #474747 0%, #333 100%);
		--app-background-component-input: rgba(var(--app-color-mono-10-decimal), 0.5);
		--app-background-component-input-hover: rgba(var(--app-color-mono-10-decimal), 0.7);
		--app-border-color-env-indicator: var(--app-color-mono-9);
	}

	&[data-theme='light'] {
		--app-color-mono-1: #000;
		--app-color-mono-2: #111;
		--app-color-mono-3: #222;
		--app-color-mono-4: #333;
		--app-color-mono-5: #555;
		--app-color-mono-6: #999;
		--app-color-mono-7: #ccc;
		--app-color-mono-8: #ddd;
		--app-color-mono-9: #eee;
		--app-color-mono-10: #fff;

		--app-color-mono-1-decimal: 0, 0, 0;
		--app-color-mono-2-decimal: 17, 17, 17;
		--app-color-mono-3-decimal: 34, 34, 34;
		--app-color-mono-4-decimal: 51, 51, 51;
		--app-color-mono-5-decimal: 85, 85, 85;
		--app-color-mono-6-decimal: 153, 153, 153;
		--app-color-mono-7-decimal: 204, 204, 204;
		--app-color-mono-8-decimal: 221, 221, 221;
		--app-color-mono-9-decimal: 238, 238, 238;
		--app-color-mono-10-decimal: 255, 255, 255;

		--app-background-component-page: var(--app-color-mono-10);
		--app-background-component-sidebar: linear-gradient(180deg, #f2f2f2 0%, #e6e6e6 100%);
		--app-background-component-panel: linear-gradient(180deg, #f8f8f8 0%, #f1f1f1 100%);
		--app-background-component-popover: linear-gradient(180deg, #f2f2f2 0%, #e6e6e6 100%);
		--app-background-component-button: linear-gradient(180deg, #f2f2f2 0%, #dedede 100%);
		--app-background-component-hover-button: linear-gradient(180deg, #f8f8f8 0%, #e4e4e4 100%);
		--app-background-component-input: rgba(var(--app-color-mono-1-decimal), 0.05);
		--app-background-component-input-hover: rgba(var(--app-color-mono-1-decimal), 0.1);
		--app-border-color-env-indicator: var(--app-color-mono-8);
	}

	// ENVS
	&[data-env='dev'] {
		--app-solid-color-env: #66c607;
		--app-gradient-color-env: linear-gradient(135deg, #0eb004 0%, #c4e40a 100%);
		--app-color-lighter-env: #8fff88;
		--app-color-env: var(--app-solid-color-env);
		--app-color-text-highlight: rgba(61, 207, 53, 0.25);
	}

	&[data-env='test'] {
		--app-solid-color-env: #f57d1d;
		--app-gradient-color-env: linear-gradient(135deg, #f54e25 0%, #f5c413 100%);
		--app-color-lighter-env: #ffee8c;
		--app-color-env: var(--app-solid-color-env);
		--app-color-text-highlight: rgba(244, 140, 29, 0.25);
	}

	&[data-env='stage'] {
		--app-solid-color-env: #0d9dbc;
		--app-gradient-color-env: linear-gradient(135deg, #0783f4 0%, #2cba85 100%);
		--app-color-lighter-env: #8dd5f8;
		--app-color-env: var(--app-solid-color-env);
		--app-color-text-highlight: rgba(19, 178, 254, 0.25);
	}

	&[data-env='prod'] {
		--app-solid-color-env: #eb2264;
		--app-gradient-color-env: linear-gradient(135deg, #d900cd 0%, #f63d29 100%);
		--app-color-lighter-env: #ff919e;
		--app-color-env: var(--app-solid-color-env);
		--app-color-text-highlight: rgba(245, 18, 45, 0.25);
	}
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

#coach {
	z-index: var(--app-z-index-layer-1500);
}

body {
	background: var(--app-background-component-page);
	font-family: 'Rubik', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin: 0;
}

ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

figure {
	margin: 0;
}

pre {
	border: 0;
	margin: 0;
	outline: 0;
	padding: 0;
}

a {
	text-decoration: none;
}

label {
	@extend %label-reset;
}

button,
input[type='button'] {
	@extend %button-reset;
}

input[type='checkbox'] {
	@extend %checkbox-reset;
}

input[type='radio'] {
	@extend %radio-reset;
}
