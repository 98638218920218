@import '../../../styles/resources/vars';
@import '../../../styles/resources/mixins';

.container {
  --app-custom-typo-heading-color: var(--app-color-mono-3);
  display: flex;
  flex: 1;
  padding: 24px;

  &.flex-start {
    align-items: flex-start;
  }

  &.flex-center {
    align-items: center;
  }

  .title-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-left: 8px;
  }

  .subtitle {
    --app-custom-typo-heading-weight: 400;
  }


  .icon {
    --app-custom-icon-height: 64px;
    --app-custom-icon-width: 64px;
    --app-custom-icon-color: var(--app-color-mono-3);

    margin-right: 8px;
  }
}
