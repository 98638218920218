@import '../../../../styles/resources/vars';
@import '../../../../styles/resources/mixins';

.container {
	align-items: center;
	display: flex;
	gap: 5px;
	height: 100%;
	width: calc(100% - 24px);

	.tags {
		align-items: center;
		display: flex;
		flex-wrap: nowrap;
		overflow: hidden;
		height: 100%;
		width: 100%;

		.tag {
			--app-custom-icon-height: 14px;
			--app-custom-icon-width: 14px;
			--app-custom-icon-color: var(--app-color-env);
			--app-custom-typo-paragraph-color: var(--app-color-mono-3);
			align-items: center;
			display: flex;
			gap: 5px;
			padding: var(--app-table-cell-padding);
			flex-shrink: 0;
			max-width: 100%;
		}
	}
}
