@import '../../../../styles/resources/vars';
@import '../../../../styles/resources/mixins';

.container {
	align-items: center;
	display: flex;
	margin-bottom: 12px;

	.logo {
		--app-custom-image-with-overlay-height: 104px;
		--app-custom-image-with-overlay-width: 104px;
		--app-custom-image-with-overlay-border-radius: 25%;
		margin-right: 24px;
		cursor: pointer;
	}

	.info-container {
		align-items: flex-start;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		width: 100%;

		.info {
			display: flex;
			align-items: center;
			gap: 33px;
			.env {
				width: auto;
			}
		}
	}
}
