@import '../../../styles/resources/vars';
@import '../../../styles/resources/mixins';

.container {
  --app-radio-icon-tick-color: transparent;

  &.dark {
    --app-radio-icon-background-color: url('#paint_radial_gradient_dark');
    box-shadow: 0 4px 4px -1px rgba(var(--app-color-mono-10-decimal), 0.4), inset 0px 1px 0px rgba(var(--app-color-mono-1-decimal), 0.08);
  }

  &.light {
    --app-radio-icon-background-color: var(--app-background-component-input);
    box-shadow: 0 4px 4px rgba(var(--app-color-mono-1-decimal), 0.12), inset 0 1px 0 var(--app-color-mono-10);
  }
}

.container:not(.checked):hover {
  &.dark {
    --app-radio-icon-background-color: url('#paint_radial_gradient_dark_hover');
    --app-radio-icon-tick-color: var(--app-color-mono-8);
  }

  &.light {
    --app-radio-icon-background-color: url('#paint_radial_gradient_light_hover');
    --app-radio-icon-tick-color: var(--app-color-mono-7);
  }
}

.container {
  display: inline-block;
  font-size: 0;
  border-radius: 20px;
  border: 1px solid var(--app-background-component-input);
}

.checked {
  &.dark {
    --app-radio-icon-tick-color: var(--app-color-env);
    --app-radio-icon-background-color: var(--app-background-component-input);
    background-color: var(--app-background-component-input);
    box-shadow: none;
  }

  &.light {
    --app-radio-icon-background-color: url('#paint_radial_gradient_light');
    --app-radio-icon-tick-color: var(--app-color-mono-10);
    background-color: var(--app-color-env);
    box-shadow: inset 0 2px 2px rgba(var(--app-color-mono-1-decimal), 0.08);
  }
}

.label {
  cursor: pointer;
}

.icon {
  display: block;
  height: 24px;
  width: 24px;

  [data-background-filter] {
    filter: var(--app-radio-icon-background-filter);
  }

  [data-background] {
    fill: var(--app-radio-icon-background-color);
  }

  [data-tick-filter] {
    filter: var(--app-radio-icon-tick-filter);
  }

  [data-tick] {
    fill: var(--app-radio-icon-tick-color);
    opacity: var(--app-radio-icon-tick-opacity);
  }
}
