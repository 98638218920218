@import '../../../../styles/resources/vars';
@import '../../../../styles/resources/mixins';

.container {
	display: flex;
	flex-direction: column;
	gap: 24px;
	padding: 24px 0;
	width: 50%;
}
