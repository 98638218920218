@import '../../../styles/resources/vars';
@import '../../../styles/resources/mixins';

.container {
	display: flex;
	flex-direction: row;
	height: calc(100vh - 154px);
	justify-content: center;
	overflow: hidden;
}

.selector {
	--app-select-input-padding: 7px 8px;
	--app-select-item-height: auto;
	--app-select-item-padding: 8px;
	--app-select-item-margin: 4px 0;
}
