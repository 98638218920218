@import '../../../../../../../styles/resources/vars';
@import '../../../../../../../styles/resources/mixins';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1;
}

.tab {
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 8px 8px 16px;
  gap: 4px;

  color: var(--light-foregrounds-3, var(--app-color-mono-3));
  border-bottom: 2px solid var(--light-foregrounds-8, var(--app-color-mono-8));
  --app-custom-icon-color: var(--app-color-mono-6);
  background: var(--app-background-component-panel);
}

.selected-tab {
  background: var(--light-backgrounds-page, var(--app-color-mono-10));
  border-bottom: 2px solid var(--light-env-solids-live, var(--app-solid-color-env));
  --app-custom-icon-color: var(--app-color-env);
}
