@import '../../../styles/resources/vars';
@import '../../../styles/resources/mixins';

.button {
	--app-custom-button-width: 32px;
	--app-custom-button-height: 32px;
}

.icon {
	--app-custom-icon-width: 18px;
	--app-custom-icon-height: 18px;

	display: flex;
}
