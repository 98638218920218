@import '../../../styles/resources/vars';
@import '../../../styles/resources/mixins';

.container {
	--app-input-height: var(--app-custom-input-height, 38px);
	--app-input-width: var(--app-custom-input-width, 100%);
	--app-input-padding: var(--app-custom-input-padding, 0 6px);
	--app-input-background: var(--app-custom-input-background, var(--app-background-component-input));
	--app-input-hover-background: var(--app-custom-input-hover-background, var(--app-background-component-input-hover));
	--app-input-focus-background: var(--app-custom-input-focus-background, var(--app-background-component-input-hover));
	--app-input-border: var(--app-custom-input-border, 1px solid transparent);
	--app-input-hover-border: var(--app-custom-input-hover-border, var(--app-input-border));
	--app-input-focus-border: var(--app-custom-input-focus-border, 1px solid var(--app-color-env));
	--app-input-border-radius: var(--app-custom-input-border-radius, 6px);
	--app-input-selection-background: var(--app-custom-input-selection-background, var(--app-color-text-highlight));
}

.error {
	--app-custom-input-border: 1px solid var(--app-color-red);
}

.error-wrapper {
	align-items: center;
	display: flex;
	gap: 5px;
	margin-left: 5px;
	margin-top: 5px;

	.error-icon {
		--app-custom-icon-width: 18px;
		--app-custom-icon-height: 18px;
		--app-custom-icon-color: var(--app-color-env);
	}

	.error-message {
		--app-custom-typo-label-color: var(--app-color-red);
	}
}

.container {
	@extend %input-reset;

	background: var(--app-input-background);
	border: var(--app-input-border);
	border-radius: var(--app-input-border-radius);
	height: var(--app-input-height);
	padding: var(--app-input-padding);
	width: var(--app-input-width);

	&::selection {
		background-color: var(--app-input-selection-background);
	}

	&:hover {
		background: var(--app-input-hover-background);
		border: var(--app-input-hover-border);
	}

	&:focus {
		background: var(--app-input-focus-background);
		border: var(--app-input-focus-border);
	}
}

.react-datepicker-wrapper {
	width: 100%;
}
