@import '../../../../../../../../../../../styles/resources/vars';
@import '../../../../../../../../../../../styles/resources/mixins';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tab {
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  border-bottom: 2px solid var(--app-color-mono-7);
  --app-custom-icon-color: var(--app-color-mono-5);
}

.selected-tab {
  border-bottom: 2px solid var(--light-env-solids-live, var(--app-solid-color-env));
  --app-custom-icon-color: var(--app-color-mono-3);
}
