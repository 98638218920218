@import '../../../../styles/resources/vars';
@import '../../../../styles/resources/mixins';

.container {
  --app-custom-typo-heading-weight: 500;
  --app-custom-typo-heading-color: var(--app-color-mono-4);
  --app-custom-typo-label-color: var(--app-color-mono-5);
  --app-textarea-min-height: 32px;
  padding: 13px 8px;
  position: relative;

  .heading {
    margin-bottom: 8px;
    margin-top: 4px;
  }

  .body {
    position: relative;

    .label {
      display: block;
      margin-bottom: 4px;
    }

    .link {
      color: var(--app-color-mono-3);
      font-size: 11px;
      font-style: italic;
      font-weight: 500;
      line-height: 120%;
      position: absolute;
      right: 0;
      text-decoration-line: underline;
      top: 0;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &::before {
    background-color: var(--app-color-mono-10);
    bottom: 0;
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    width: 100%;
  }
}
