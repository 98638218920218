@import '../../../../styles/resources/vars';
@import '../../../../styles/resources/mixins';

.container {
  display: flex;
  align-items: center;
  gap: 8px;
  --app-custom-typo-label-color: var(--app-color-mono-5);

  .loader {
    --app-custom-icon-width: 24px;
    --app-custom-icon-height: 24px;
    --app-icon-color: var(--app-color-env);
  }

  .failed-status {
    display: flex;
    align-items: center;
    gap: 8px;

    --app-custom-icon-height: 18px;
    --app-custom-icon-width: 18px;
    --app-custom-icon-color: var(--app-color-mono-5);
  }
}