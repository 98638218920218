@import '../../../../../styles/resources/vars';
@import '../../../../../styles/resources/mixins';

.container {
  display: flex;

  .sidebar {
    --app-custom-sidebar-width: 240px;
    flex: 0 0 240px;
  }
}

