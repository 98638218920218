@import '../../styles/resources/vars';
@import '../../styles/resources/mixins';

.header {
	align-items: baseline;
	display: flex;
}

.back {
	--app-custom-icon-height: 18px;
	--app-custom-icon-width: 18px;
	--app-custom-icon-color: var(--app-color-mono-5);

	margin-right: 11px;
}

.description {
	flex: 1;

	&.with-back-button {
		position: relative;
		right: 15px;
	}
}
