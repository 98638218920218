@import '../../../../styles/resources/vars';
@import '../../../../styles/resources/mixins';

.container {
	align-items: var(--app-table-cell-align);
	cursor: default;
	display: flex;
	flex-direction: column;
	justify-content: var(--app-table-cell-vertical-align);
}

.row {
	--app-custom-typo-label-color: var(--app-color-mono-5);
	--app-custom-typo-label-font-weight: 700;
	--app-custom-typo-label-font-size: 12px;
	align-items: var(--app-table-cell-vertical-align);
	display: flex;
	height: var(--app-table-row-height);
	justify-content: var(--app-table-cell-align);
	padding: var(--app-table-cell-padding);
	width: 100%;

	&:hover {
		--app-custom-typo-label-color: var(--app-color-mono-1);
		background-color: var(--app-background-component-input);
	}
}

.input {
	--app-custom-form-label-align-items: center;
	--app-custom-typo-label-font-style: italic;
	cursor: pointer;
}
