@import '../../../../styles/resources/vars';
@import '../../../../styles/resources/mixins';

.container {

	--app-custom-typo-heading-weight: 500;
	--app-custom-sidebar-width: 274px;
	flex: 0 0 274px;

	.heading {
		background: transparent;
	}

	.item {
		padding: 0;

		&:not(:last-child) {
			margin-bottom: 5px;
		}
	}
}
