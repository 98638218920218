@import '../../../../../../../../../styles/resources/vars';
@import '../../../../../../../../../styles/resources/mixins';

.container {
  display: flex;
  flex-direction: column;

  --app-custom-typo-heading-weight: 500;
  box-shadow: 0px -2px 0px 0px var(--app-color-mono-10) inset;

  padding-bottom: 16px;
}