@import '../../styles/resources/vars';
@import '../../styles/resources/mixins';

.container {
	--app-popover-width: var(--app-custom-popover-width, auto);
	--app-popover-max-width: var(--app-custom-popover-max-width, 320px);
	--app-popover-padding: var(--app-custom-popover-padding, 16px);
	--app-popover-border: var(--app-custom-popover-border, 1px solid var(--app-color-mono-9));
	--app-popover-border-radius: var(--app-custom-popover-border-radius, 12px);
	--app-popover-filter: var(--app-custom-popover-filter, drop-shadow(0 16px 48px rgba(0, 0, 0, 0.5)));
	--app-popover-background: var(--app-custom-popover-background, var(--app-background-component-popover));
}

.container {
	background: var(--app-popover-background);
	border: var(--app-popover-border);
	border-radius: var(--app-popover-border-radius);
	filter: var(--app-popover-filter);
	max-width: var(--app-popover-max-width);
	padding: var(--app-popover-padding);
	width: var(--app-popover-width);
}
