@import '../../../../../../../styles/resources/vars';
@import '../../../../../../../styles/resources/mixins';

.variant {
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-width: 375px;
}

.name {
  color: var(--dark-backgrounds-input-hover, rgba(var(--app-color-mono-1-decimal), 0.70));
  font-family: Rubik, sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.block {
  border: 1px solid rgba(var(--app-color-mono-1-decimal), 0.10);
  width: 100%;

  &.image {
    display: flex;
    justify-content: center;
    border: none;
  }
}
