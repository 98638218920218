@import '../../../../styles/resources/vars';
@import '../../../../styles/resources/mixins';

.container {
	color: var(--app-custom-typo-label-color, var(--app-color-mono-2));
	font-size: var(--app-custom-typo-label-font-size, 12px);
	font-style: var(--app-custom-typo-label-font-style, normal);
	font-weight: var(--app-custom-typo-label-font-weight, 400);
	letter-spacing: var(--app-custom-typo-label-letter-spacing);
	line-height: var(--app-custom-typo-label-line-height);
	text-align: var(--app-custom-typo-label-text-align, left);
	text-decoration: var(--app-custom-typo-label-decoration, none);
	vertical-align: var(--app-custom-typo-label-vertical-align);
	white-space: var(--app-custom-contenteditable-text-white-space);
	width: 100%;
	word-break: var(--app-custom-contenteditable-text-word-break);
}

.size-l {
	font-size: 18px;
	letter-spacing: -0.01em;
	line-height: 1.2;
}

.size-m {
	font-size: 15px;
	letter-spacing: -0.03em;
	line-height: 1.2;
}

.size-s {
	font-size: 13px;
	line-height: 1.2;
}

.size-xs {
	font-size: 11px;
	line-height: 1.2;
}
