@import '../../../styles/resources/vars';
@import '../../../styles/resources/mixins';

.buttons {
	margin-top: 14px;

	> :not(:first-child) {
		margin-top: 8px;
	}
}
