@import '../../../../../../../../../../../styles/resources/vars';
@import '../../../../../../../../../../../styles/resources/mixins';

.container {
  position: relative;
}

.link {
  color: var(--app-color-mono-3);
  font-size: 11px;
  font-style: italic;
  font-weight: 500;
  line-height: 120%;
  position: absolute;
  right: 0;
  text-decoration-line: underline;
  top: 0;
}