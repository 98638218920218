@import '../../../../../../styles/resources/vars';
@import '../../../../../../styles/resources/mixins';

.item {
	padding: 0;

	&:not(:last-child) {
		margin-bottom: 5px;
	}
}

.descendants {
	padding-left: 30px;

	.descendant {
		margin-top: 5px;
		padding: 0;
	}
}
