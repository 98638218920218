@import '../../../../../../../../../../../styles/resources/vars';
@import '../../../../../../../../../../../styles/resources/mixins';

.layer {
  display: inline-block;
  height: auto;
  width: 100%;

  cursor: pointer;
  outline-offset: -1px;
  outline: 1px solid transparent;

  &.selected {
    outline: 1px solid var(--app-color-env);
  }

  &:hover {
    outline: 1px dashed var(--app-color-env);
  }
}
