@import '../../../styles/resources/vars';
@import '../../../styles/resources/mixins';

.label {
	display: grid;
}

.container {
	font-size: 0;
}

.input {
	@extend %input-reset;
	height: 100%;
	width: 100%;
}

.input,
.empty {
	min-width: 1em;
}

.input,
.value {
	grid-area: 1/1;
	white-space: pre-wrap;
}

.placeholder {
	color: var(--app-color-mono-6);
}

.value {
	@extend %inherit;
}

.label,
.button {
	overflow: hidden;
	overflow-wrap: break-word;
	white-space: pre-wrap;
	word-break: keep-all;
}

.button {
	cursor: inherit;
	min-width: 0;
}

.hidden {
	color: transparent;
}

.empty {
	display: block;

	&::before {
		content: '.';
		visibility: hidden;
	}
}
