@import '../../../../styles/resources/vars';
@import '../../../../styles/resources/mixins';

.popover {
  --app-custom-popover-width: 400px;
  --app-custom-popover-max-width: 400px;
  height: 540px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  & > div {
   margin: 0;
  }
}

.buttons {
  display: flex;
  flex-direction: row-reverse;
  gap: 6px;
}
