@import '../../../../styles/resources/vars';
@import '../../../../styles/resources/mixins';

.container {
	color: var(--app-color-mono-2);
	font-size: 15px;
	font-style: italic;
	font-weight: normal;
	line-height: 1.4;
	margin: 8px 0;
	text-align: center;
}
