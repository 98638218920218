@import '../../styles/resources/vars';
@import '../../styles/resources/mixins';

.container {
	--app-contenteditable-background: var(--app-custom-contenteditable-background, none);
	--app-contenteditable-hover-background: var(--app-custom-contenteditable-hover-background,
	var(--app-background-component-input-hover));
	--app-contenteditable-focus-background: var(--app-custom-contenteditable-focus-background,
	var(--app-background-component-input-hover));
	--app-contenteditable-border-radius: var(--app-custom-contenteditable-border-radius, 6px);
	--app-contenteditable-border: var(--app-custom-contenteditable-border, 1px solid transparent);
	--app-contenteditable-hover-border: var(--app-custom-contenteditable-hover-border, var(--app-contenteditable-border));
	--app-contenteditable-focus-border: var(--app-custom-contenteditable-focus-border, 1px solid var(--app-color-env));
	--app-contenteditable-padding: var(--app-custom-contenteditable-padding, 8px);
	--app-contenteditable-selection-background: var(--app-custom-contenteditable-selection-background,
	var(--app-color-text-highlight));
}

.container {
	background: var(--app-contenteditable-background);
	border: var(--app-contenteditable-border);
	border-radius: var(--app-contenteditable-border-radius);
	cursor: text;
	padding: var(--app-contenteditable-padding);

	&:hover {
		background: var(--app-contenteditable-hover-background);
		border: var(--app-contenteditable-hover-border);
	}

	&:focus-within {
		background: var(--app-contenteditable-focus-background);
		border: var(--app-contenteditable-focus-border);
	}

	:focus {
		outline: none;
	}
}
