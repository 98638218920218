@import '../../../../styles/resources/vars';
@import '../../../../styles/resources/mixins';

.container {
	--app-custom-input-background: none;
	--app-custom-input-hover-background: none;
	--app-custom-input-focus-background: none;
	--app-custom-input-border: 0;
	--app-custom-input-hover-border: 0;
	--app-custom-input-focus-border: 0;
	--app-custom-input-border-radius: 0;
	--app-custom-input-font-style: italic;
}
