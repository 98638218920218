@import '../../../styles/resources/vars';
@import '../../../styles/resources/mixins';

.container {
	background-color: rgba(var(--app-color-mono-9-decimal), 0.75);
	z-index: 2000;
}

.body {
	--app-overlay-body-padding: 24px 32px;
}

.body {
	background: var(--app-custom-overlay-background, var(--app-background-component-sidebar));
	height: 100%;
	max-width: var(--app-custom-overlay-max-width, 80%);
	min-width: 320px;
	padding: var(--app-custom-overlay-padding, var(--app-overlay-body-padding));
}
