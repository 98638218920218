@import '../../../../styles/resources/vars';
@import '../../../../styles/resources/mixins';

.container {
	align-items: var(--app-table-cell-vertical-align);
	display: flex;
	height: 100%;
	justify-content: var(--app-table-cell-align);
	padding: var(--app-table-cell-padding);
	width: 100%;
}

.item {
	--app-custom-button-height: 24px;
	--app-custom-button-padding: 3px 9px;


	&.icon-only {
		--app-custom-button-padding: 0;
	}

	&.loading {
		svg {
			--app-icon-width: 14px;
			--app-icon-height: 14px;
		}
	}
}

.action {
	&:not(:last-child) {
		margin-right: 6px;
	}
}
