@import '../../../../../../styles/resources/vars';
@import '../../../../../../styles/resources/mixins';

.heading {
	margin-bottom: 15px;
	text-align: center;
}

.label {
	--app-custom-icon-width: 18px;
	--app-custom-icon-height: 18px;
	--app-custom-icon-color: var(--app-color-env);
	align-items: center;
	cursor: pointer;
	display: flex;
	margin-bottom: 8px;
	margin-top: 8px;
	padding: 0 0 0 22px;
}
