@import '../../styles/resources/vars';
@import '../../styles/resources/mixins';

.container {
	display: flex;
	flex-direction: column;
	height: 100%;
	padding: 0 24px;
}

.header {
	display: flex;
	padding: 16px 0;
}

.icon {
	--app-custom-icon-height: 64px;
	--app-custom-icon-width: 64px;
	--app-custom-icon-color: var(--app-color-mono-3);

	margin-right: 8px;
}

.intro {
	align-items: flex-start;
	display: flex;
	flex: 1;
	flex-direction: column;
	min-width: 0;
}

.title {
	--app-custom-contenteditable-border-radius: 6px;
	--app-custom-contenteditable-padding: 8px;
}

.description {
	--app-custom-contenteditable-border-radius: 6px;
	--app-custom-contenteditable-padding: 8px;
}
