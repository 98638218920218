@import '../../../../styles/resources/vars';
@import '../../../../styles/resources/mixins';

.headline {
	--app-custom-typo-heading-weight: 500;
	margin: 12px 0;
}

.form {
	--app-custom-form-field-margin: 8px;
}
