@import '../../../../../../../../../../../styles/resources/vars';
@import '../../../../../../../../../../../styles/resources/mixins';

.heading {
  --app-custom-typo-heading-color: var(--app-color-mono-4-decimal);
  --app-custom-typo-heading-weight: 500;
  margin-bottom: 12px;
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}