@import '../../../../../styles/resources/vars';
@import '../../../../../styles/resources/mixins';

.container {
	position: relative;
}

.popover {
	--app-custom-popover-width: 100%;
	--app-custom-popover-padding: 4px 6px;
	--app-custom-popover-border-radius: 6px;

	--app-custom-icon-width: 15px;
	--app-custom-icon-height: 15px;
	--app-custom-icon-color: var(--app-color-env);
}

.popover {
	align-items: center;
	display: flex;
}

.icon {
	margin-left: 4px;
}
