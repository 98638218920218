@import '../../../../styles/resources/vars';
@import '../../../../styles/resources/mixins';

.container {
	--app-custom-icon-size: 32px;
	--app-custom-radio-user-select: none;
	--app-custom-typo-heading-weight: 500;

	&:not(:first-child) {
		margin-top: 30px;
	}
}

.headline {
	cursor: pointer;
	display: flex;
	align-items: center;
	margin: 0 0 12px;
	user-select: var(--app-custom-radio-user-select, unset);
}

.client {
	--app-custom-typo-label-font-style: italic;
	--app-custom-typo-label-color: var(--app-color-mono-5);
	--app-custom-typo-label-line-height: 130%;
	--app-custom-typo-label-letter-spacing: -0.01em;
}

.icon {
	--app-custom-icon-color: var(--app-color-mono-5);
	--app-custom-icon-width: var(--app-custom-icon-size);
	--app-custom-icon-height: var(--app-custom-icon-size);
}

.logo {
	--app-image-with-overlay-height: var(--app-custom-icon-size);
	--app-image-with-overlay-width: var(--app-custom-icon-size);
	border: 1px solid var(--app-border-color-env-indicator);
	margin-right: 8px;
}

.options {
	padding-left: var(--app-custom-icon-size);
}
