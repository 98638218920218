@import '../../../../styles/resources/vars';
@import '../../../../styles/resources/mixins';

.container {
	cursor: pointer;
	height: 100%;
	width: 100%;
}

.popover {
	--app-custom-popover-width: 320px;
	--app-custom-typo-heading-weight: 500;
}
