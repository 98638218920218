@import '../../styles/resources/vars';
@import '../../styles/resources/mixins';

.container {
	@extend %scroll-bar-reset;
	color: var(--app-color-mono-3);
	display: grid;
	font-family: Rubik;
	font-size: 15px;
	font-style: normal;
	font-weight: normal;
	line-height: 20px;
	overflow-wrap: break-word;
	overflow-x: scroll;
	position: relative;
	text-align: left;
	white-space: pre-wrap;
	word-break: keep-all;
}

.edit {
	-webkit-font-smoothing: antialiased;
	-webkit-text-fill-color: transparent;
}

.prism-code {
	@extend %inherit;
	pointer-events: none;
}

.edit,
.prism-code {
	grid-area: 1/1;
}

.token-line {
	position: relative;

	&::before {
		color: var(--app-color-mono-5);
		content: attr(data-number);
		font-family: Rubik;
		font-size: 11px;
		font-style: normal;
		font-weight: normal;
		left: 0;
		line-height: 20px;
		position: absolute;
	}
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
	color: #5b9954;
}

.token.punctuation {
	color: #f8f8f2;
}

.token.namespace {
	opacity: 0.7;
}

.token.property,
.token.tag,
.token.constant,
.token.symbol,
.token.deleted {
	color: #f92672;
}

.token.boolean,
.token.number {
	color: #a79df7;
}

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
	color: #ff8170;
}

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string,
.token.variable {
	color: #f8f8f2;
}

.token.atrule,
.token.attr-value,
.token.function,
.token.class-name {
	color: #e6db74;
}

.token.keyword {
	color: #66d9ef;
}

.token.regex,
.token.important {
	color: #fd971f;
}

.token.important,
.token.bold {
	font-weight: bold;
}

.token.italic {
	font-style: italic;
}

.token.entity {
	cursor: help;
}
