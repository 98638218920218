@import '../../../../styles/resources/vars';
@import '../../../../styles/resources/mixins';

.container {
	--app-main-menu-categories-border-width: 1px;
	--app-main-menu-categories-border-style: solid;
	--app-main-menu-categories-border-color: var(--app-color-mono-10);

	--app-custom-grid-gap-column: 0px;
}

.heading {
	padding: 8px 8px 8px 16px;
}

.features {
	--app-custom-grid-rows: 99px;

	border-color: var(--app-main-menu-categories-border-color);
	border-style: var(--app-main-menu-categories-border-style);
	border-width: 0 var(--app-main-menu-categories-border-width) var(--app-main-menu-categories-border-width) 0;
}

.feature {
	border-color: var(--app-main-menu-categories-border-color);
	border-style: var(--app-main-menu-categories-border-style);
	border-width: var(--app-main-menu-categories-border-width) 0 0 var(--app-main-menu-categories-border-width);
}

.link {
	align-items: center;
	display: flex;
	justify-content: flex-start;
	padding: 18px 16px;

	&:hover {
		background: var(--app-background-component-input);
	}
}

.icon {
	--app-custom-icon-height: 64px;
	--app-custom-icon-width: 64px;

	margin-right: 16px;

	@at-root .link:hover & {
		--app-custom-icon-color: var(--app-color-env);
	}

	@at-root .dark.link:hover [data-icon] {
		filter: url('#glow');
	}
}
