@import '../../../styles/resources/vars';
@import '../../../styles/resources/mixins';

.container {
	display: flex;
}

.item {
	text-decoration: none;
	width: fit-content;

	span:first-child {
		color: var(--app-color-mono-6);
		margin-right: 4px;
	}

	a {
		color: inherit;
	}

	&:not(:last-child) {
		margin-right: 4px;
	}
}

.active {
	font-weight: 700;
}

.clipped {
	max-width: 20vw;
}
