@import '../../../../styles/resources/vars';
@import '../../../../styles/resources/mixins';

.container {
	--app-table-cell-cursor: pointer;
	--app-custom-typo-label-font-style: italic;
	--app-custom-typo-label-font-weight: 500;
	--app-custom-typo-label-decoration: underline;
	align-items: center;
	display: flex;
	height: 100%;
	justify-content: space-between;
	position: relative;

	.button {
		--app-custom-button-height: 24px;
		--app-custom-button-width: 24px;
		--app-custom-button-padding: 0;
		--app-button-icon-color: var(--app-background-component-input-hover);

		display: none;
		position: absolute;
		right: 7px;
	}

	&:hover {
		.button {
			display: block;
		}

		&.light {
			background: rgba(0, 0, 0, 0.05);
		}

		&.dark {
			background: var(--app-color-mono-8);
		}
	}
}
