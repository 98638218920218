@import '../../styles/resources/vars';
@import '../../styles/resources/mixins';

.container {
	margin: 32px auto;
	max-width: 1360px;
}

.hero,
.main {
	--app-grid-gap-column: 40px;
}

.main {
	--app-custom-grid-cols: 2;
	--app-custom-grid-rows: 1fr 1fr;
	margin-top: 32px;
}
