@import '../../../styles/resources/vars';
@import '../../../styles/resources/mixins';

.container {
	display: flow-root;
	padding: 0 8px;
	width: var(--app-custom-sidebar-width, 240px);
}

.title {
	margin: 12px 0;
	--app-custom-typo-heading-color: var(--app-color-mono-4);
}

.item {
	border-top: 1px solid var(--app-color-mono-7);
	display: flex;
	flex-direction: column;
	padding: 8px 0;
}

.without-border {
	border: 0;
}

.row {
	flex-direction: row;
}

.relative {
	position: relative;
}

.link {
	color: var(--app-color-mono-3);
	font-size: 11px;
	font-style: italic;
	font-weight: 500;
	line-height: 120%;
	position: absolute;
	right: 8px;
	text-decoration-line: underline;
	top: 8px;
}
