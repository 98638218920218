@import '../../../../styles/resources/vars';
@import '../../../../styles/resources/mixins';

.container {
	--app-form-label-color: var(--app-custom-form-label-color, var(--app-color-mono-5));
	--app-form-label-margin: var(--app-custom-form-label-margin, 4px);
	--app-form-label-align-items: var(--app-custom-form-label-align-items, stretch);

	display: block;
	width: 100%;

	&:not(:last-child) {
		margin-right: 8px;
	}
}

.provider {
	align-items: var(--app-form-label-align-items);
	display: flex;
	height: 100%;

	&.left {
		flex-direction: row;
	}

	&.right {
		flex-direction: row-reverse;
	}

	&.top {
		flex-direction: column;
	}

	&.bottom {
		flex-direction: column-reverse;
	}
}

.label-container {
	display: flex;
	flex: 1;

	.wrapper {
		--app-custom-typo-label-color: var(--app-form-label-color);
		align-items: center;
		cursor: pointer;
		display: flex;

		@at-root .left & {
			margin-right: var(--app-form-label-margin);
		}

		@at-root .right & {
			margin-left: var(--app-form-label-margin);
		}

		@at-root .top & {
			margin-bottom: var(--app-form-label-margin);
		}

		@at-root .bottom & {
			margin-top: var(--app-form-label-margin);
		}
	}

	.asterisk {
		--app-custom-icon-width: 10px;
		--app-custom-icon-height: 10px;
		--app-custom-icon-color: var(--app-color-env);
		align-self: flex-start;
	}
}



