@import '../../../../../../../../../../../../../styles/resources/vars';
@import '../../../../../../../../../../../../../styles/resources/mixins';

.container {
  display: flex;
  flex-direction: column;
  cursor: pointer;

  .item {
    padding: 4px;
    display: flex;
    align-items: center;
    border-radius: 6px;
    border: 1px solid transparent;

    &.selected {
      border: 1px solid var(--app-color-env);
    }
  }

  .child {
    padding-left: 30px;
  }
}
