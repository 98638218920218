@import '../../styles/resources/vars';
@import '../../styles/resources/mixins';

.container {
	background: var(--app-custom-bubble-background-color, var(--app-color-mono-10));
	border-radius: 6px;
	color: var(--app-custom-bubble-background-color, var(--app-color-mono-3));
	font-size: var(--app-custom-bubble-font-size, 13px);
	font-style: var(--app-custom-bubble-font-style, italic);
	font-weight: var(--app-custom-bubble-font-weight, 400);
	line-height: var(--app-custom-bubble-line-height, 18px);
	padding: 8px 12px;
}
