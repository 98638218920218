@import '../../../../styles/resources/vars';
@import '../../../../styles/resources/mixins';

.container {
	cursor: var(--app-table-cell-cursor, default);
	display: flex;
	height: 100%;
	width: 100%;
}

.input,
.label {
	align-items: var(--app-table-cell-vertical-align);
	flex: 1;
	justify-content: var(--app-table-cell-align);
}

.input {
	--app-custom-contenteditable-border-radius: 0;
	--app-custom-contenteditable-padding: var(--app-table-cell-padding);
	--app-custom-contenteditable-hover-background: none;
	--app-custom-contenteditable-focus-background: none;
}

.wrap {
	--app-custom-contenteditable-text-white-space: pre-line;
	--app-custom-contenteditable-text-word-break: break-word;
}

.label {
	display: flex;
	overflow: hidden;
	padding: var(--app-table-cell-padding);
}

.text {
	width: 100%;
}
