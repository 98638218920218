@import '../../../../../styles/resources/vars';
@import '../../../../../styles/resources/mixins';

.buttons {
  margin-top: 8px;
  --app-custom-button-width: 50%;

  display: flex;
  gap: 7px;
}
