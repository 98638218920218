@import '../../styles/resources/vars';
@import '../../styles/resources/mixins';

.container {
  align-items: center;
  display: flex;
  flex: 1;

  border-bottom: 1px solid var(--app-color-mono-9);

  .icon {
    --app-custom-icon-height: 64px;
    --app-custom-icon-width: 64px;
    --app-custom-icon-color: var(--app-color-mono-3);

    margin-right: 8px;
  }
}

.item-container {
  padding: 24px;
}
