@import '../../../../../styles/resources/vars';
@import '../../../../../styles/resources/mixins';

.container {
  display: flex;
  justify-content: center;

  .canvas {
    height: calc(100vh - 48px);
    box-shadow: 0 0 24px 0 rgba(var(--app-color-mono-1-decimal), 0.1);
    border: 1px solid rgba(var(--app-color-mono-9-decimal), 1);
    overflow-y: scroll;
  }
}