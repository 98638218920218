%inherit {
	color: inherit;
	font-family: inherit;
	font-size: inherit;
	font-stretch: inherit;
	font-style: inherit;
	font-variant: inherit;
	font-variant-ligatures: inherit;
	font-weight: inherit;
	letter-spacing: inherit;
	line-height: inherit;
	tab-size: inherit;
	text-align: inherit;
	text-indent: inherit;
	text-rendering: inherit;
	text-transform: inherit;
}

%thrown-away {
	clip: rect(0 0 0 0);
	clip-path: inset(50%);
	height: 0;
	overflow: hidden;
	position: relative;
	white-space: nowrap;
	width: 0;
	z-index: -1;
}

%truncated-text {
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

%input-reset {
	@extend %inherit;

	appearance: none;
	background: none;
	border: 0;
	border-radius: 0;
	margin: 0;
	outline: none;
	padding: 0;
	resize: none;

	&::selection {
		background-color: var(--app-contenteditable-selection-background);
	}

	&::placeholder {
		@extend %inherit;
	}
}

%checkbox-reset {
	@extend %thrown-away;
	appearance: none;
	outline: none;
}

%radio-reset {
	@extend %thrown-away;
	appearance: none;
	outline: none;
}

%file-reset {
	@extend %thrown-away;
	appearance: none;
	outline: none;
}

%scroll-bar-reset {
	-ms-overflow-style: none;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}
}

%label-reset {
	color: inherit;
	font: inherit;
	letter-spacing: normal;
	line-height: normal;
}

%button-reset {
	background: transparent;
	border: 0;
	color: inherit;
	cursor: pointer;
	font: inherit;
	line-height: normal;
	margin: 0;
	outline: none;
	overflow: visible;
	padding: 0;
	text-align: unset;
	width: auto;

	&::-moz-focus-inner {
		border: 0;
		padding: 0;
	}
}

%input-spin-buttons-reset {
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	&[type=number] {
		-moz-appearance: textfield; /* Firefox */
	}
}
