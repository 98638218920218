@import '../../styles/resources/vars';
@import '../../styles/resources/mixins';

.container {
	--app-table-row-height: var(--app-custom-table-row-height, 40px);
	--app-table-row-bottom-border: var(--app-custom-table-row-bottom-border, 1px solid var(--app-color-mono-8));
	--app-table-heading-cell-vertical-align: var(--app-custom-table-heading-cell-vertical-align, flex-start);
	--app-table-heading-cell-align: var(--app-custom-table-heading-cell-align, flex-start);
	--app-table-cell-padding: var(--app-custom-table-cell-padding, 6px 8px);
	--app-table-cell-vertical-align: var(--app-custom-table-cell-vertical-align, center);
	--app-table-cell-align: var(--app-custom-table-cell-align, flex-start);

	--app-table-sticky-background: var(--app-background-component-page);
	--app-table-sticky-box-shadow: -8px 0 16px 0px var(--app-background-component-input);
}

.container {
	min-height: 0;
	min-width: 0;
}

.container[data-loading='true'] {
	cursor: wait;
}

.wrapper {
	max-height: calc(100vh - 48px);
	overflow-y: scroll;

	.sticky-header {
		background: var(--app-background-component-page);
		position: sticky;
		top: 0;
		z-index: 2;
	}
}

.row {
	align-items: stretch;
	border-bottom: var(--app-table-row-bottom-border);
	display: flex;
	min-width: 100%;

	@at-root .container[data-loading='true'] & {
		pointer-events: none;
	}

	> .sticky {
		box-shadow: var(--app-table-sticky-box-shadow);
		clip-path: inset(0 0 0 -16px);
	}

	> .sticky ~ .sticky {
		box-shadow: none;
	}
}

.highlighted {
	border: 1px solid var(--app-color-env);
}

.heading {
	border-right: var(--app-table-row-bottom-border);
	min-height: var(--app-table-row-height);
}

.cell {
	border-right: var(--app-table-row-bottom-border);
	min-height: var(--app-table-row-height);
}

.sorted {
	&.light {
		background: rgba(0, 0, 0, 0.03);
	}

	&.dark {
		background: rgba(0, 0, 0, 0.5);
	}
}

.sticky {
	background: var(--app-table-sticky-background);
	position: sticky !important;
	z-index: 1;
}

.empty {
	flex-grow: 100;
	width: auto !important;
}


