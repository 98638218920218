@import '../../../styles/resources/vars';
@import '../../../styles/resources/mixins';

.container {
  display: flex;
  align-items: center;
  height: 100%;
}

.tab {
  padding: 16px;
  position: relative;
  color: var(--app-color-mono-5);
  font-weight: 700;
  font-size: 13px;
  cursor: pointer;

  &.active {
    background-color: var(--app-color-mono-10);
    color: var(--app-color-mono-3);
    margin-bottom: -3px;
    &::before {
      content: '';
      position: absolute;
      top: -2px;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: var(--app-color-env);
    }
  }
}