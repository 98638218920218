@import '../../../styles/resources/vars';
@import '../../../styles/resources/mixins';

.button-container {
	width: var(--app-select-width, auto);
}

.popover-container {
	z-index: 1500;
}

.in-overlay {
	z-index: 2001;
}

.popover {
	--app-custom-popover-padding: 0 6px;
	--app-custom-popover-border-radius: var(--app-custom-select-border-radius, 6px);
	max-height: inherit;
	overflow-y: auto;
	width: var(--app-select-width, auto);
}

.list {
	min-height: 30px;
	padding: 8px 0;
}

.button {
	align-items: center;
	background: var(--app-select-button-background, var(--app-background-component-input));
	border-radius: var(--app-select-button-border-radius, 6px);
	display: flex;
	height: var(--app-select-input-height, auto);
	justify-content: space-between;
	padding: var(--app-select-input-padding, 0 6px);
	width: 100%;

	&:hover,
	&:focus {
		background: var(--app-background-component-input-hover);
	}
}

.item {
	align-items: center;
	border-radius: 6px;
	display: flex;
	height: var(--app-select-item-height, 30px);
	justify-content: space-between;
	margin: var(--app-select-item-margin, 0);
	padding: var(--app-select-item-padding, 0 8px);

	&[aria-disabled='true'] {
		opacity: 0.3;
	}

	&:hover {
		background: rgba(var(--app-color-mono-10-decimal), 0.24);
	}

	@at-root .light & {
		&:hover {
			background: rgba(var(--app-color-mono-1-decimal), 0.1);
		}
	}
}

.icon {
	--app-custom-icon-color: var(--app-color-mono-5);
	--app-custom-icon-width: 18px;
	--app-custom-icon-height: 18px;
	--app-custom-icon-margin: 0 0 0 4px;
}

.selected {
	--app-custom-icon-color: var(--app-color-env);
}

.cross {
	// resetting cross button styles
	--app-button-outer-background: transparent;
	--app-button-inner-background: transparent;
	--app-button-height: 16px;
	--app-button-width: 16px;
	--app-button-padding: 0;
	--app-button-border-radius: none;
	--app-button-inner-border-radius: none;
	--app-button-outer-border: none;
	--app-button-inner-border: none;
	--app-button-outer-box-shadow: none;
	--app-button-inner-box-shadow: none;
	position: absolute;
	right: 12px;

	@at-root .container:not(.loading):hover & {
		--app-button-inner-background: transparent;
	}
}
