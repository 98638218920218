@import '../../../../../../styles/resources/vars';
@import '../../../../../../styles/resources/mixins';

.container {
	margin-bottom: 8px;
}
.popover {
	--app-custom-popover-width: 320px;
	--app-custom-typo-heading-weight: 500;
}
