@import '../../../styles/resources/vars';
@import '../../../styles/resources/mixins';

.container,
.label {
	display: inline-grid;
}

.error {
	--app-custom-contenteditable-border: 1px solid var(--app-color-red);
}

.error-wrapper {
	align-items: center;
	display: flex;
	gap: 5px;
	margin-left: 5px;
	margin-top: 5px;

	.error-icon {
		--app-custom-icon-width: 18px;
		--app-custom-icon-height: 18px;
		--app-custom-icon-color: var(--app-color-env);
	}

	.error-message {
		--app-custom-typo-label-color: var(--app-color-red);
	}
}

.input {
	@extend %input-reset;
	width: 100%;
}

.input,
.empty {
	min-width: 1em;
}

.input,
.value {
	grid-area: 1/1;
}

.value {
	@extend %inherit;
}

.value {
	overflow: hidden;
	visibility: hidden;
}

.value,
.text {
	white-space: var(--app-custom-contenteditable-text-white-space, pre);
	word-break: var(--app-custom-contenteditable-text-word-break, normal);
}



.button {
	align-items: flex-start;
	cursor: inherit;

	display: flex;
	min-width: 0;
	width: 100%;

	.asterisk {
		--app-custom-icon-width: 10px;
		--app-custom-icon-height: 10px;
		--app-custom-icon-color: var(--app-color-env);
	}
}

.empty {
	display: block;

	&::before {
		content: '.';
		visibility: hidden;
	}
}
