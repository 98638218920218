@import '../../../../styles/resources/vars';
@import '../../../../styles/resources/mixins';

.toggle {
	--app-custom-form-label-align-items: center;
}

.label {
	margin-bottom: 4px;
	--app-custom-typo-label-color: var(--app-color-mono-5);
}
