@import '../../../../../../styles/resources/vars';
@import '../../../../../../styles/resources/mixins';

.container {
  position: relative;
}

.json-container {
  background: var(--app-background-component-input);
  border-radius: 6px;
  min-height: 120px;
  padding: 6px;
}

.button {
  --app-custom-button-height: 12px;
  --app-custom-button-padding: 0;
  --app-custom-button-margin: 0;
  --app-button-outer-background: transparent;
  --app-button-inner-background: transparent;
  --app-background-component-hover-button: transparent;
  --app-button-outer-box-shadow: none;
  --app-button-inner-box-shadow: none;
  --app-button-outer-border: none;
  --app-button-inner-border: none;

  display: block;
  position: absolute;
  right: 0;
  top: 0;

  & span {
    text-decoration: underline;
    font-size: 11px;
  }
}
