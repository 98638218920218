@import '../../../../styles/resources/vars';
@import '../../../../styles/resources/mixins';

.container {
	border: var(--app-custom-form-field-border, 0);
	padding: var(--app-custom-form-field-padding, 0);

	&:not(:last-child) {
		margin-bottom: var(--app-custom-form-field-margin, 16px);
	}
}
