@import '../../../../styles/resources/vars';
@import '../../../../styles/resources/mixins';

.container {
	--app-tickbox-icon-background-color: transparent;
	--app-tickbox-icon-tick-color: var(--app-color-mono-10);
	align-items: center;
	display: flex;
	height: 38px;
}

.checked {
	&.dark {
		--app-tickbox-icon-tick-color: var(--app-color-mono-1);
		--app-tickbox-icon-tick-filter: url('#filter1_dark');
		--app-tickbox-icon-background-color: rgba(var(--app-color-mono-10-decimal, 0.5));
	}

	&.light {
		--app-tickbox-icon-background-filter: url('#filter0_light');
		--app-tickbox-icon-background-color: var(--app-color-mono-6);
		--app-tickbox-icon-tick-filter: url('#filter1_light');
	}
}

.label {
	display: flex;
}

.icon {
	display: block;
	height: 20px;
	width: 20px;

	[data-background-filter] {
		filter: var(--app-tickbox-icon-background-filter);
	}

	[data-background] {
		fill: var(--app-tickbox-icon-background-color);
	}

	[data-tick-filter] {
		filter: var(--app-tickbox-icon-tick-filter);
	}

	[data-tick] {
		fill: var(--app-tickbox-icon-tick-color);
		opacity: var(--app-tickbox-icon-tick-opacity);
	}
}
