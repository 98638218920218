@import '../../../../../../../../../../../styles/resources/vars';
@import '../../../../../../../../../../../styles/resources/mixins';

.container {
  display: flex;
  align-items: center;
  gap: 7px;

  --app-custom-button-width: 49px;
  --app-custom-button-height: 30px;
}