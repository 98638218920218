@import '../../../../../../styles/resources/vars';
@import '../../../../../../styles/resources/mixins';

.files {
  flex: 1;
  height: 100%;
  overflow-y: scroll;
}

.list-item {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 4px;
}

.thumb-container {
  --app-custom-image-with-overlay-height: 100%;
  --app-custom-image-with-overlay-border-radius: 10px;
  --app-custom-image-with-overlay-width: 100%;
  flex-shrink: 0;
}

.icon-container {
  & > div {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.thumb-container, .icon-container {
  height: 64px;
  width: 64px;

  &:hover {
    cursor: pointer;
  }
}

.label-container {
  white-space: pre-line;
  word-break: break-word;
}
