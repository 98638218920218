@import '../../../styles/resources/vars';
@import '../../../styles/resources/mixins';

.container {
	--app-icon-width: var(--app-custom-icon-width, 32px);
	--app-icon-height: var(--app-custom-icon-height, 32px);
	--app-icon-color: var(--app-custom-icon-color, var(--app-background-component-input));
}

.container {
	display: block;
	height: var(--app-icon-width);
	width: var(--app-icon-height);
}

.circle {
	fill: transparent;
	stroke: var(--app-icon-color);
	stroke-linecap: round;

	@at-root .animate.multi-coloured & {
		&:nth-of-type(7) {
			stroke: var(--app-color-red);
		}

		&:nth-of-type(6) {
			stroke: var(--app-color-purple);
		}

		&:nth-of-type(5) {
			stroke: var(--app-color-indigo);
		}

		&:nth-of-type(4) {
			stroke: var(--app-color-sky-blue);
		}

		&:nth-of-type(3) {
			stroke: var(--app-color-green);
		}

		&:nth-of-type(2) {
			stroke: var(--app-color-yellow);
		}

		&:nth-of-type(1) {
			stroke: var(--app-color-orange);
		}
	}
}
