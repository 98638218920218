@import '../../../../../../../../../styles/resources/vars';
@import '../../../../../../../../../styles/resources/mixins';

.container {
  padding: 0 8px 8px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  gap: 12px;

  --app-textarea-min-height: 38px;
  --app-custom-textarea-padding: 10px 6px;
}
