@import '../../../../styles/resources/vars';
@import '../../../../styles/resources/mixins';

.container {
	--app-select-input-height: 100%;
	height: 100%;

	&.light {
		background: var(--app-color-mono-10);
	}

	&.dark {
		background: transparent;
	}

	.value {

		--app-select-button-border-radius: 0;
		--app-custom-typo-value-color: var(--app-color-mono-3);
		height: 100%;

		&.light {
			--app-select-button-background: var(--app-color-mono-10);
		}

		&.dark {
			--app-select-button-background: transparent;
		}
	}

	.select {
		height: 100%;
	}

	.heading {
		text-align: center;
	}
}
