@import '../../../../../../../styles/resources/vars';
@import '../../../../../../../styles/resources/mixins';

.container {
  display: flex;
  flex-direction: column;
  padding: 8px 8px 20px;

  .heading {
    --app-custom-typo-heading-color: var(--app-color-mono-4);
    line-height: 48px;
  }

  .layer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    padding-left: 18px;
  }

  &:nth-child(2) > div {
    padding-left: 0;
  }

  .block-name-container {
    display: flex;
    align-items: center;
    .caret {
      --app-custom-icon-color: var(--app-color-mono-6);
      --app-custom-icon-width: 10px;
      --app-custom-icon-height: 10px;
      transform: rotate(90deg);
    }

    .block-name {
      padding-left: 8px;
      --app-custom-typo-paragraph-color: var(--app-color-mono-3);
      line-height: 26px;
    }
  }

  .content {
    padding-left: 39px;
    --app-custom-typo-paragraph-color: var(--app-color-mono-3);
    line-height: 26px;
  }
}
