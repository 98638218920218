@import '../../../../../../styles/resources/vars';
@import '../../../../../../styles/resources/mixins';

.container {
  --app-custom-icon-height: 47px;
  --app-custom-icon-width: 36px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}