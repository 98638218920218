@import '../../../../styles/resources/vars';
@import '../../../../styles/resources/mixins';

.popover {
	--app-custom-popover-width: 320px;
}

.cross {
	// resetting cross button styles
	--app-button-outer-background: transparent;
	--app-button-inner-background: transparent;
	--app-button-height: 16px;
	--app-button-width: 16px;
	--app-button-padding: 0;
	--app-button-border-radius: none;
	--app-button-inner-border-radius: none;
	--app-button-outer-border: none;
	--app-button-inner-border: none;
	--app-button-outer-box-shadow: none;
	--app-button-inner-box-shadow: none;
	position: absolute;
	right: 18px;
	top: 18px;

	@at-root .container:not(.loading):hover & {
		--app-button-inner-background: transparent;
	}
}

.message {
	--app-custom-typo-paragraph-text-align: center;
	margin-bottom: 20px;
}

.label {
	--app-custom-typo-label-font-size: 11px;
}

.code {
	--app-textarea-font-height: 20px;
	margin: 4px 0 0;
}

.button {
	--app-button-margin: 17px 0 0;
}

.loading {
	--app-button-inner-background: var(--app-gradient-color-env);
	--app-custom-loading-icon-color: var(--app-color-mono-10);
}

.heading {
	--app-custom-typo-heading-weight: 500;
	display: flex;
	justify-content: center;
}
