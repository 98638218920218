@import '../../../../styles/resources/vars';
@import '../../../../styles/resources/mixins';

.container {
	align-items: flex-start;
	display: flex;
	flex: 1;
	padding: 24px;

	.error-state {
		align-items: flex-start;
		display: flex;
		flex-direction: column;
	}

	.icon {
		--app-custom-icon-height: 64px;
		--app-custom-icon-width: 64px;
		--app-custom-icon-color: var(--app-color-mono-3);

		margin-right: 8px;
	}
}
