@import '../../../styles/resources/vars';
@import '../../../styles/resources/mixins';

.popover {
	position: fixed;
	width: 100%;
}

.container {
	background: var(--app-background-component-panel);
	display: flex;
	height: 100vh;
	overflow: hidden;
}

.sections {
	--app-custom-grid-gap-column: 16px;
	--app-custom-grid-rows: 1fr;

	flex: 1;

	grid-template-columns: 240px 1fr;
	margin: 16px 16px 0;

	.sidebar {
		height: 100%;
		max-height: calc(100vh - 48px);
		padding-bottom: 48px;
		width: 240px;
	}
}

.headline {
	margin: 12px 0;
}
