@import '../../../../styles/resources/vars';
@import '../../../../styles/resources/mixins';

.container {
	--app-custom-panel-margin-bottom: 0;
	border-radius: 10px;
	padding: 16px;
}

.item {
	display: flex;
	flex-direction: column;

	&:not(:first-child) {
		margin-top: 24px;
	}

	.label {
		align-items: center;
		display: flex;
		margin-bottom: 12px;

		.name {
			--app-custom-typo-label-color: var(--app-color-mono-5);
			margin-left: 10px;
		}
	}
}

.messages {
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;

	.bubble {
		&:not(:last-child) {
			margin-bottom: 4px;
			width: 100%;
		}

		&:last-child {
			width: auto;
		}

		&:only-child {
			width: 100%;
		}
	}
}
