@import '../../../../styles/resources/vars';
@import '../../../../styles/resources/mixins';

.panel {
	--app-custom-panel-margin-bottom: 0;
	border-radius: 10px;
}

.text {
	padding: 48px;
	text-align: center;
}
