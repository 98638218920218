@import '../../../../styles/resources/vars';
@import '../../../../styles/resources/mixins';

.container {
	align-items: center;

	border-bottom: 1px solid rgba(var(--app-color-mono-1-decimal), 0.1);
	border-top: 1px solid rgba(var(--app-color-mono-1-decimal), 0.1);
	display: flex;
	flex-wrap: wrap;
	gap: 5px;
	margin-bottom: 12px;
	padding: 12px 0;

	.item {
		--app-custom-icon-color: var(--app-color-env);
		--app-custom-icon-width: 18px;
		--app-custom-icon-height: 18px;
		align-items: center;
		border: 1px solid var(--app-color-env);
		border-radius: 6px;
		cursor: pointer;
		display: flex;
		gap: 5px;
		padding: 4px 6px;
		max-width: 100%;
	}
}
