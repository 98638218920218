@import '../../../../styles/resources/vars';
@import '../../../../styles/resources/mixins';

.container {
	--app-custom-form-field-margin: 10px;

	--app-custom-form-label-color: var(--app-color-mono-4);
	--app-custom-form-label-margin: 8px;

	user-select: var(--app-custom-radio-user-select, unset);
}
