@import '../../../../styles/resources/vars';
@import '../../../../styles/resources/mixins';

.container {
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 54px 0;
}

.icon {
	--app-icon-height: 32px;
	--app-icon-width: 32px;
	--app-icon-color: var(--app-color-env);
}
