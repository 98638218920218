@import '../../../../../../../styles/resources/vars';
@import '../../../../../../../styles/resources/mixins';

.container {
  display: flex;
  flex-direction: column;

  & > div {
    padding: 8px;
    border-bottom: 2px solid var(--app-color-mono-10);
  }
}
