@import '../../../../styles/resources/vars';
@import '../../../../styles/resources/mixins';

.container {
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 8px;
  position: relative;

  &.selected {
    border: 1px solid var(--app-color-env);
  }

  .thumb-container {
    height: 64px;
    width: 64px;
    --app-custom-image-with-overlay-height: 100%;
    --app-custom-image-with-overlay-border-radius: 10px;
    --app-custom-image-with-overlay-width: 100%;
    flex-shrink: 0;
  }

  .label-container {
    overflow: hidden;
  }

  .wrap {
    --app-custom-contenteditable-text-white-space: pre-line;
    --app-custom-contenteditable-text-word-break: break-word;
  }

  .replace-button {
    --app-custom-button-height: 22px;
    --app-custom-button-padding: 2px;
    flex: 1;
    justify-content: flex-end;
    display: none;
  }

  &:hover {
    .replace-button {
      display: flex;
      position: absolute;
      right: 8px;
    }
  }
}