@import '../../../../../../../../styles/resources/vars';
@import '../../../../../../../../styles/resources/mixins';

.container {

	--app-custom-icon-color: var(--app-color-env);
	align-items: center;
	display: flex;
	flex-direction: column;
	margin-top: 80px;

	p {

		color: var(--app-color-mono-3);
		font-size: 15px;
		font-style: italic;
		line-height: 1.4;
		margin-top: 14px;
		text-align: center;
		width: 320px;
	}
}
