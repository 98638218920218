@import '../../../../styles/resources/vars';
@import '../../../../styles/resources/mixins';

.container {
	position: relative;
}

.popover-container {
	z-index: 2001;
}

.popover {
	--app-custom-popover-width: 320px;
}

.actions {
	display: flex;
}

.action:not(:last-child) {
	margin-right: 8px;
}
