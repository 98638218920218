@import '../../../../styles/resources/vars';
@import '../../../../styles/resources/mixins';

.heading-container {
	margin-bottom: 15px;

	.heading {
		text-align: center;
	}

	.back-icon {
		--app-custom-icon-height: 18px;
		--app-custom-icon-width: 18px;
		--app-custom-icon-color: var(--app-color-mono-5);
		cursor: pointer;
		position: absolute;
	}
}

.no-parent {
	--app-custom-typo-label-color: var(--app-color-mono-4);
	cursor: pointer;
	margin-bottom: 12px;
	margin-left: 8px;
	margin-top: 12px;
}
