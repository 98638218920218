@import '../../../../../../../../styles/resources/vars';
@import '../../../../../../../../styles/resources/mixins';

.rule {
	align-items: center;
	display: flex;

	&:not(:last-child) {
		margin-bottom: 8px;
	}

	.rule-body {
		align-items: center;
		display: flex;
		gap: 8px;
	}

	.rule-actions {
		align-items: center;
		display: flex;
		gap: 15px;
		margin-left: 15px;
	}

	.default-condition {
		text-align: center;
		width: 64px;
	}

	.rule-value {
		--app-custom-input-height: 32px;
		--app-custom-input-width: 219px;
	}

	.back-icon {
		--app-custom-icon-height: 18px;
		--app-custom-icon-width: 18px;
		--app-custom-icon-color: var(--app-color-mono-5);
		cursor: pointer;
		left: 12px;
		position: absolute;
	}

	.rule-operator {
		--app-select-width: 64px;
	}

	.select-container {
		--app-select-input-padding: 7px 8px;
		--app-select-item-height: auto;
		--app-select-item-padding: 8px;
		--app-select-item-margin: 4px 0;

		--app-select-width: 219px;
	}

	.label-container {
		display: flex;
		justify-content: space-between;
		width: 100%;
	}

	.condition {
		--app-custom-switch-width: 64px;
		--app-custom-switch-control-border-radius: 4px;
		--app-custom-switch-control-height: 24px;
		--app-custom-switch-control-width: 16px;
		--app-custom-switch-control-off-state-transform: -41px;
		--app-custom-switch-height: 32px;
		--app-custom-switch-checked-background: var(--app-background-component-input);
		--app-custom-switch-box-shadow: inset 0px 2px 2px rgba(var(--app-color-mono-1-decimal), 0.1);
	}

	.rule-action {
		--app-custom-icon-width: 18px;
		--app-custom-icon-height: 18px;

		--app-custom-icon-color: var(--app-color-mono-5);
		cursor: pointer;
		display: block;
	}
}
