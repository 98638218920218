@import '../../styles/resources/vars';
@import '../../styles/resources/mixins';

.container {
	--app-icon-color: var(--app-custom-icon-color, var(--app-color-mono-1));
	--app-icon-height: var(--app-custom-icon-height, 24px);
	--app-icon-width: var(--app-custom-icon-width, 24px);
	--app-icon-margin: var(--app-custom-icon-margin, 0);

	align-items: center;
	display: inline-flex;
	justify-content: center;

	> svg {
		color: var(--app-icon-color);
		display: block;
		fill: none;
		height: var(--app-icon-height);
		stroke: none;
		width: var(--app-icon-width);
		margin: var(--app-icon-margin);
	}
}

.iconMargin {
	--app-icon-margin: 0 0 0 4px;
}
