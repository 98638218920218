@import '../../../../../../../../../../../styles/resources/vars';
@import '../../../../../../../../../../../styles/resources/mixins';

.item {
  display: list-item;
}

.thumbnail {
  margin-top: 5px;
  --app-image-with-overlay-height: 78px;
  --app-image-with-overlay-width: 224px;
  border: 1px solid rgba(var(--app-color-mono-9-decimal), 1);
  border-radius: 6px;
}