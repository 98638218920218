@import '../../../../../../../styles/resources/vars';
@import '../../../../../../../styles/resources/mixins';

.container {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.button {
  --app-custom-button-height: 32px;
  --app-custom-button-width: 32px;
  --app-custom-button-padding: 0;

  @at-root .container:hover & {
    --app-button-inner-background: var(--app-background-component-hover-button);
  }
}
