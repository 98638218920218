@import '../../../../styles/resources/vars';
@import '../../../../styles/resources/mixins';

.label {
	--app-custom-typo-label-color: var(--app-color-mono-5);
	display: block;
	margin-bottom: 4px;
}

.add-new-tag {
	--app-custom-icon-width: 18px;
	--app-custom-icon-height: 18px;
	--app-custom-icon-color: var(--app-color-env);
	align-items: center;

	border-top: 1px solid rgba(var(--app-color-mono-1-decimal), 0.1);
	cursor: pointer;
	display: flex;
	margin-top: 12px;
	padding: 16px 0 16px 22px;
}
