@import '../../../../styles/resources/vars';
@import '../../../../styles/resources/mixins';

.container {
	align-items: center;
	display: flex;
	margin-top: 10px;
}

.icon {
	--app-custom-icon-height: 18px;
	--app-custom-icon-width: 18px;
	--app-custom-icon-color: var(--app-color-env);

	margin: 0 6px;
}

.text {
	--app-custom-typo-label-color: var(--app-color-mono-5);

	flex: 1;
}
