@import '../../../../../styles/resources/vars';
@import '../../../../../styles/resources/mixins';

.textarea {
  margin: 8px 0;
  --app-custom-textarea-padding: 8px;
}

.label {
  --app-custom-typo-label-color: var(--app-color-mono-5);
}