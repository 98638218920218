@import '../../../../../../../../styles/resources/vars';
@import '../../../../../../../../styles/resources/mixins';

.ruleset {
	background: var(--app-background-component-panel);
	border-radius: 10px;
	padding: 16px;

	&:not(:last-child) {
		margin-bottom: 12px;
	}

	&:not(:first-child) {
		margin-top: 12px;
	}

	.add-rule {
		--app-custom-icon-width: 18px;
		--app-custom-icon-height: 18px;
		--app-custom-button-height: 30px;
		--app-custom-button-width: 62px;
		--app-custom-button-padding: 0;

		--app-custom-icon-color: var(--app-color-mono-5);
	}
}

.ruleset-condition {
	--app-custom-switch-width: 64px;
	--app-custom-switch-control-border-radius: 4px;
	--app-custom-switch-control-height: 24px;
	--app-custom-switch-control-width: 16px;
	--app-custom-switch-control-off-state-transform: -41px;
	--app-custom-switch-height: 32px;
	--app-custom-switch-checked-background: var(--app-background-component-input);
	--app-custom-switch-box-shadow: inset 0px 2px 2px rgba(var(--app-color-mono-1-decimal), 0.1);
	margin-left: 16px;
}
