@import '../../../../../../../styles/resources/vars';
@import '../../../../../../../styles/resources/mixins';

.container {
  display: flex;
  gap: 8px;

  --app-select-input-height: 38px;

  & > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
}
