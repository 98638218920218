@use 'sass:list';
@use 'sass:map';

@import '../../../styles/resources/vars';
@import '../../../styles/resources/mixins';

$max-cols: 12;

.provider {
  --app-grid-cols: clamp(1, var(--app-custom-grid-cols, #{$max-cols}), #{$max-cols});
  --app-grid-rows: var(--app-custom-grid-rows, min-content);
  --app-grid-gap-row: var(--app-custom-grid-gap-row, 0px);
  --app-grid-gap-column: var(--app-custom-grid-gap-column, 0px);
  --app-grid-width: var(--app-custom-grid-width, 100%);
  --app-grid-margin: var(--app-custom-grid-margin, 1fr);
}

.provider {
  // Using clamp inside a calc function throws an Error during the build
  --LOCAL-width: clamp(min(100%, var(--app-grid-width)), var(--app-grid-width) + var(--app-grid-gap-column) * 2, 100%);

  display: grid;
  grid-auto-rows: var(--app-grid-rows);
  grid-gap: var(--app-grid-gap-row) var(--app-grid-gap-column);
  grid-template-columns:
		[edge-start]
		var(--app-grid-margin)
		[row-start]
		repeat(var(--app-grid-cols),
                [col-start] calc((var(--LOCAL-width) - (var(--app-grid-gap-column) * (var(--app-grid-cols) - 1) + var(--app-grid-gap-column) * 2 + var(--app-custom-grid-margin, 0px) * 2)) / var(--app-grid-cols)) [col-end])
		[row-end]
		var(--app-grid-margin)
		[edge-end];
  justify-content: center;
}

.fill {
  grid-template-columns: repeat(auto-fill, minmax(calc((min(100%, var(--app-grid-width)) - var(--app-grid-gap-column) * (var(--app-grid-cols) - 1)) / var(--app-grid-cols)), 1fr));
}

.no-gutter {
  grid-template-columns:
		[row-start]
		repeat(var(--app-grid-cols),
                [col-start] calc((min(100%, var(--app-grid-width)) - var(--app-grid-gap-column) * (var(--app-grid-cols) - 1)) / var(--app-grid-cols)) [col-end])
		[row-end];
}

.column {
  --app-template-column-start: var(--app-custom-template-column-start, row-start);
  --app-template-column-end: var(--app-custom-template-column-end, row-end);
}

.column {
  grid-column-end: var(--app-template-column-end);
  grid-column-start: var(--app-template-column-start);
}

.auto {
  grid-column: auto;
}

@for $i from 1 through $max-cols {
  .span-#{$i} {
    --app-template-column-end: span #{$i};
  }

  .start-#{$i} {
    --app-template-column-start: col-start #{$i};
  }
}

@include on-breakpoint(span, true) {
  @for $i from 1 through $max-cols {
    &-#{$i} {
      --app-template-column-end: span #{$i};
    }
  }
}

@include on-breakpoint(start, true) {
  @for $i from 1 through $max-cols {
    &-#{$i} {
      --app-template-column-start: col-start #{$i};
    }
  }
}

.edge {
  --app-template-column-start: edge-start;
  --app-template-column-end: edge-end;
}

@include on-breakpoint(edge, true) {
  --app-template-column-start: edge-start;
  --app-template-column-end: edge-end;
}

.hide {
  display: none;
}

@include on-breakpoint(hide, true) {
  display: none;
}

$direction: (
        row: 'row',
        row-reverse: 'row-reverse',
        column: 'column',
        column-reverse: 'column-reverse'
);
$wrap: (
        wrap: 'wrap',
        reverse: 'wrap-reverse'
);
$align: (
        start: 'flex-start',
        end: 'flex-end',
        center: 'center',
        baseline: 'baseline',
        stretch: 'stretch'
);
$justify: map.merge($align,
        (
                between: 'space-between',
                around: 'space-around',
                evenly: 'space-evenly'
        ));
$self: (
        start: 'start',
        end: 'end'
);

.flex {
  display: flex;
}

@each $category,
$map in (flex-direction: (
        'flex-direction',
        $direction
),
        flex-wrap: (
                'flex-wrap',
                $wrap
        ),
        flex-align: (
                'align-items',
                $align
        ),
        flex-justify: (
                'justify-content',
                $justify
        ),
        flex-self: (
                'align-self',
                $self
        )
) {
  $property: list.nth($map, 1);
  $helper: list.nth($map, 2);

  @each $h-name, $h-value in $helper {
    .#{$category}-#{$h-name} {
      @if ($property == 'flex-direction') {
        display: flex;
      }

      #{$property}: #{$h-value};
    }
  }

  @include on-breakpoint(#{$category}, true) {
    @each $h-name, $h-value in $helper {
      &-#{$h-name} {
        #{$property}: #{$h-value};
      }
    }
  }
}
