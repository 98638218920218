@import '../../../../../../styles/resources/vars';
@import '../../../../../../styles/resources/mixins';

.container {
	--app-select-input-height: 100%;
	--app-select-width: 100%;
	height: 100%;

	.value {
		height: 100%;
	}

	.select {
		--app-custom-popover-max-width: 100%;
		height: 38px;
	}
}
