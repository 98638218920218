@import '../../styles/resources/vars';
@import '../../styles/resources/mixins';

.container {
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 24px 0;

	.loading-icon {
		--app-icon-height: 32px;
		--app-icon-width: 32px;
		--app-icon-color: var(--app-color-env);
	}

	.text {
		--app-custom-typo-label-color: var(--app-color-mono-3);
		margin-top: 8px;
		text-align: center;
	}
}
