@import '../../../../styles/resources/vars';
@import '../../../../styles/resources/mixins';

.container {
	align-items: center;
	display: flex;

	&:first-child:last-child {
		margin-right: 8px;
	}

	&:not(:last-child) {
		margin-right: 8px;
	}
}
