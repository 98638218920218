@import '../../../../../../styles/resources/vars';
@import '../../../../../../styles/resources/mixins';

.container {
	display: flex;
	flex-direction: column;

	.item {
		--app-custom-typo-label-color: var(--app-color-mono-3);
		--app-custom-icon-color: var(--app-color-env);
		--app-custom-icon-width: 18px;
		--app-custom-icon-height: 18px;
		align-items: center;
		border: 1px solid transparent;
		cursor: pointer;
		display: flex;
		gap: 5px;
		grid-gap: 5px;
		padding: 4px;
		max-width: 100%;

		&.disabled {
			opacity: 0.4;
		}

		.caret {
			--app-custom-icon-color: var(--app-color-mono-6);
			--app-custom-icon-width: 10px;
			--app-custom-icon-height: 10px;
			transition: transform 0.1s linear;

			&.expanded {
				transform: rotate(90deg);
			}
		}
	}

	.children {
		padding-left: 30px;
	}

	&:not(:last-child) {
		margin-bottom: 5px;
	}
}

.children-tree {
	--app-custom-tags-list-height: auto;
	--app-custom-tags-list-overflow: auto;
	--app-custom-tags-list-margin-bottom: 5px;
}
