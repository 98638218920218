@import '../../../../../../../../styles/resources/vars';
@import '../../../../../../../../styles/resources/mixins';

.container {
  display: flex;
  justify-content: center;
  width: 100%;

  .image-wrapper {
    --app-custom-image-with-overlay-border-radius: 0;
    --app-custom-image-with-overlay-height: 100%;
    --app-custom-image-with-overlay-width: 100%;
    --app-image-object-fit: contain;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon-wrapper {
    width: 100%;
    height: 100%;
    & > div {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      & > svg {
        width: 512px;
        height: 512px;
      }
    }
  }
}
.loader {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 54px 0;

  .icon {
    --app-icon-height: 32px;
    --app-icon-width: 32px;
    --app-icon-color: var(--app-color-env);
  }
}