@import '../../../styles/resources/vars';
@import '../../../styles/resources/mixins';

.container {
  --app-checkbox-icon-width: var(--app-custom-checkbox-icon-width, 20px);
  --app-checkbox-icon-height: var(--app-custom-checkbox-icon-height, 20px);
  --app-checkbox-icon-tick-color: var(--app-custom-checkbox-icon-tick-color, transparent);
  --app-checkbox-icon-background-filter: none;
  --app-checkbox-icon-padding: 1px;

  &.dark {
    --app-checkbox-icon-container-background-color: #3b3b3b;
    --app-checkbox-icon-background-color: url('#paint_radial_gradient_dark');
    box-shadow: 0 4px 4px -1px rgba(var(--app-color-mono-10-decimal), 0.4), inset 0px 1px 0px rgba(var(--app-color-mono-1-decimal), 0.08);
  }

  &.light {
    --app-checkbox-icon-container-background-color: transparent;
    --app-checkbox-icon-background-color: var(--app-background-component-input);
    box-shadow: 0 4px 4px rgba(var(--app-color-mono-1-decimal), 0.12), inset 0 1px 0 var(--app-color-mono-10);
  }
}

.container:not(.checked):hover {
  &.dark {
    --app-checkbox-icon-background-color: url('#paint_radial_gradient_dark_hover');
    --app-checkbox-icon-tick-color: var(--app-color-mono-8);
  }

  &.light {
    --app-checkbox-icon-background-color: url('#paint_radial_gradient_light_hover');
    --app-checkbox-icon-tick-color: var(--app-color-mono-7);
  }
}

.container {
  display: inline-block;
  font-size: 0;
  border-radius: 7px;
  border: 1px solid var(--app-background-component-input);
}

.checked {
  --app-checkbox-icon-padding: 0px;

  &.dark {
    --app-checkbox-icon-container-background-color: transparent;
    --app-checkbox-icon-background-color: var(--app-background-component-input);
    --app-checkbox-icon-tick-color: var(--app-color-env);
    box-shadow: none;
  }

  &.light {
    --app-checkbox-icon-background-color: var(--app-color-env);
    --app-checkbox-icon-tick-color: var(--app-color-mono-10);
    background-color: var(--app-color-env);
    box-shadow: inset 0 1px 3px rgba(var(--app-color-mono-1-decimal), 0.24);
  }
}

.checked:hover {
  opacity: 0.7;
}

.label {
  cursor: pointer;
}

.icon {
  display: block;
  height: var(--app-checkbox-icon-width);
  width: var(--app-checkbox-icon-height);
  padding: var(--app-checkbox-icon-padding);
  background-color: var(--app-checkbox-icon-container-background-color);
  border-radius: 6px;

  [data-background-filter] {
    filter: var(--app-checkbox-icon-background-filter);
  }

  [data-background] {
    fill: var(--app-checkbox-icon-background-color);
  }

  [data-tick-filter] {
    filter: var(--app-checkbox-icon-tick-filter);
  }

  [data-tick] {
    fill: var(--app-checkbox-icon-tick-color);
    opacity: var(--app-checkbox-icon-tick-opacity);
  }
}
