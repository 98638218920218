@import '../../../../../../../../styles/resources/vars';
@import '../../../../../../../../styles/resources/mixins';

.button {
	align-items: center;
	background: var(--app-background-component-input);
	border-radius: 6px;
	display: flex;
	height: 38px;
	justify-content: space-between;
	padding: 0 6px;
	width: 100%;

	&:disabled {
		cursor: not-allowed;
		opacity: 0.4;
	}

	&:not([disabled]):hover,
	&:not([disabled]):focus {
		background: var(--app-background-component-input-hover);
	}
}

.icon {
	--app-custom-icon-color: var(--app-color-mono-5);
	--app-custom-icon-width: 18px;
	--app-custom-icon-height: 18px;
	--app-custom-icon-margin: 0 0 0 4px;
}

.popover-container {
	--app-custom-popover-max-width: 100%;
	--app-custom-popover-border-radius: 6px;
	--app-custom-typo-heading-weight: 500;
	z-index: 2001;


	.heading {
		margin-bottom: 15px;
		text-align: center;
	}
}
